export const storeItem = async (key: string, data: any) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.log("Something went wrong", error);
  }
};

export const retrieveItem = async (key: string) => {
  try {
    const data = window.localStorage.getItem(key);
    if (data) return JSON.parse(data);
    return null;
  } catch (error) {
    console.log("Something went wrong", error);
    return null;
  }
};

export const removeItem = async (key: string) => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    console.log("Something went wrong", error);
  }
};

export const formatCurrency = (x: number = 0) => {
  // eslint-disable-next-line no-useless-concat
  return "\u0024" + " " + x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatText = (text: string = "") => {
  return text?.replace(/(^|[^:’'"])\b\w/g, (match) => {
    return match.toUpperCase();
  });
};

export const formatPhoneNumberToNANPFormat = (input: string): string => {
  let digits = input.replace(/\D/g, "");

  digits = digits.substring(0, 11);

  let formattedNumber = digits.replace(
    /^1?(\d{0,3})(\d{0,3})(\d{0,4})/,
    function (_, g1, g2, g3) {
      if (g1.length === 0) return "";
      let part1 = `+1 (${g1}`;
      if (g1.length < 3) return part1;
      let part2 = `${part1}) ${g2}`;
      if (g2.length < 3) return part2;
      return `${part2}-${g3}`;
    }
  );

  return formattedNumber;
};

export const formatPhoneNumberToE164 = (input: string): string => {
  let digits = input.replace(/\D/g, "");

  digits = digits.substring(0, 11);

  let formattedNumber = digits.replace(
    /^1?(\d{0,3})(\d{0,3})(\d{0,4})/,
    function (_, g1, g2, g3) {
      if (g1.length === 0) return "";
      let part1 = `+1${g1}`;
      if (g1.length < 3) return part1;
      let part2 = `${part1}${g2}`;
      if (g2.length < 3) return part2;
      return `${part2}${g3}`;
    }
  );

  return formattedNumber;
};
