import React from "react";
import progressLogo from "../../utils/assets/progress_logo.png";
import { Box, Typography } from "@mui/material";
import { useProgressIndicatorStyles } from "./styles";
import { COLORS } from "../../utils/colors";
import { useStore } from "../../contexts/StoreContext";

interface IProps {
  message: string;
  progress: number;
  step: number;
}

const ProgressIndicator = ({ message, progress, step }: IProps) => {
  const progressIndicatorStyles = useProgressIndicatorStyles();
  const { smallScreen } = useStore();

  return (
    <Box 
      display="flex" 
      alignItems="center" 
      width="100%">
      <div
        className="progress-bar"
        style={{
          transform: smallScreen ? 'scale(.75)' : 'scale(1)',
          background: `radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(${COLORS.PRIMARY} ${progress}%, transparent 0)`,
        }}
      >
        <progress style={{ visibility: "hidden", height: 0, width: 0 }}>
          {progress}
        </progress>
        <Box sx={progressIndicatorStyles.iconWrapper}>
          <div
            style={progressIndicatorStyles.progress as React.CSSProperties}
          ></div>
          <img
            src={progressLogo}
            alt="Progress Icon"
            style={progressIndicatorStyles.icon as React.CSSProperties}
          />
        </Box>
      </div>

      <Box width="70%">
        <Typography fontWeight={600} fontSize="17px">{`${step}/3`}</Typography>
        <Typography fontWeight={500} color="#838383" fontSize="13px" display={ smallScreen ? "none" : "block" }>
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressIndicator;
