import { responsiveFontSizes, createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundSize: "cover",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "2rem",
          textTransform: "none",
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            borderColor: "#a3a3a3",
            color: "#a3a3a3",
            "&.MuiButton-outlined:hover": {
              borderColor: "black",
              backgroundColor: "#fff",
              color: "#FF6E00",
            },
          },
        },
      ],
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "2rem",
          height: "3.0rem",

          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
          "&:-internal-autofill-selected": {
            backgroundColor: "transparent",
          },
        },
        input: {
          color: "black",
          "&:-internal-autofill-selected": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&:-internal-autofill-selected": {
            backgroundColor: "transparent",
          },
        },
        input: {
          "&:-internal-autofill-selected": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: "-4px",
          "&.Mui-focused": {
            color: "black",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&:-internal-autofill-selected": {
            backgroundColor: "transparent",
          },
          '&.MuiInputBase-input::before': {
            content: '""',
            backgroundColor: 'transparent !important',
          },
          "input:-internal-autofill-selected": {
            backgroundColor: "transparent",
          },
        },
        input: {
          height: "2.5rem",
          padding: "0",
          "&:-internal-autofill-selected": {
            backgroundColor: "transparent",
          },
          "&:-internal-autofill-selected::before": {
            backgroundColor: "transparent",
          },
          "&:-internal-autofill-selected::after": {
            backgroundColor: "transparent",
          },
          '&.MuiInputBase-input::before': {
            content: '""',
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
