import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useSearchParams, Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useStore } from "../contexts/StoreContext";
import useOnSched from "../hooks/useOnSched";

const MainLayout = () => {
  const { isHeadless, setIsHeadless, setPromoCode, modalLayout, setModalLayout, setSmallScreen } = useStore();
  const [searchParams] = useSearchParams();
  const mainRef = useRef(null);
  const { deleteBooking } = useOnSched();

  useEffect(() => {
    if (searchParams.get("headless") !== null) {
      setIsHeadless(searchParams.get("headless") === "1");
      console.log('...setIsHeadless to ' + searchParams.get("headless"))
    }

    if (searchParams.get("promo_code") !== null) {
      setPromoCode(searchParams.get("promo_code"));
      console.log('...setPromoCode to ' + searchParams.get("promo_code"))
    }

    if (searchParams.get("dashboard") !== null) {
      setModalLayout(searchParams.get("dashboard") === "1" ? 'dashboard' : '');
      console.log('...setModalLayout to ' + (searchParams.get("dashboard") === "1" ? 'dashboard' : ''))
    }

    if (searchParams.get("headless") !== "1")
      document.body.classList.add("watermark");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHeadless, setIsHeadless]);

  useEffect(() => {
    if (!isHeadless) return

    document.body.style["overflowY"] = "hidden";

    const handleResize = (entries: any) => {
      setSmallScreen(entries[0]?.contentRect?.width < 850)

      const message = {
        type: "resized",
        height:
          entries[0]?.contentRect?.height > 400
            ? entries[0]?.contentRect?.height
            : 400,
      };
      window.parent.postMessage(message, "*");
    };

    const main:any = mainRef.current;
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(main);

    return () => {
      try {
        resizeObserver.disconnect();
      }
      catch (e){}
    };
  }, [isHeadless]);

  useEffect(() => {
    const local_booking_id = window.localStorage.getItem("local_booking_id");
    if (local_booking_id) {
      deleteBooking.mutate(local_booking_id);
      window.localStorage.removeItem("local_booking_id");
    }
  }, [deleteBooking]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight={isHeadless && !modalLayout ? undefined : "100vh"}
      className={modalLayout ? 'modal' : ''}
      ref={mainRef}
    >
      {!isHeadless && <Header />}
      <Box flexShrink={0} flex="1 0 auto">
        <Outlet />
      </Box>
      {!isHeadless && <Footer />}
    </Box>
  );
};

export default MainLayout;
