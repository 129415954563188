export const HOMEOWNER_CONTACT_INFORMATION = [
  {
    label: "First Name *",
    name: "firstname",
  },
  {
    label: "Last Name *",
    name: "lastname",
  },
  {
    label: "Email *",
    name: "email",
  },
  {
    label: "Mobile Number *",
    name: "mobile",
  },
];

export const HOME_ADDRESS_INFORMATION = [
  {
    label: "Street address *",
    name: "address",
    size: 6,
    disabled: false,
  },
  {
    label: "City *",
    name: "city",
    size: 6,
    disabled: false,
  },
  {
    label: "Postal Code *",
    name: "postalCode",
    size: 6,
    maxLength: 8,
    disabled: false,
  },
  {
    label: "Province *",
    name: "province",
    size: 6,
    disabled: false,
  },
  {
    label: "Country *",
    name: "country",
    size: 6,
    disabled: true,
  },
];

export const HOME_INFORMATION = [
  {
    label: "What year was your home built? E.g: 2010",
    name: "yearBuilt",
    size: 6,
    maxLength: 4,
    disabled: false,
  },
  {
    label: "Purchase year. E.g: 2010",
    name: "purchaseDate",
    size: 6,
    maxLength: 4,
    disabled: false,
  }, // {
  //   label: "Promo Code (Optional)",
  //   name: "promoCode",
  //   size: 6,
  //   maxLength: 50,
  //   disabled: false,
  // },
];
