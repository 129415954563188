import { useMutation } from "react-query";
import { axiosOrder } from "../utils/axios-orders";

const useUser = () => {
  const axios = axiosOrder();

  const verifyUserEmail = useMutation(async (email: string) => {
    const verify = async () => {
      const res = await axios.post(`users/verify_email`, { email });
      if (res.data.status === "success") {
        return res.data;
      } else {
        throw new Error("An error occurred while verifying user email");
      }
    };

    const response = await verify();
    return response;
  });

  const verifyUserMobile = useMutation(async (payload: Record<string, any>) => {
    const verify = async () => {
      const res = await axios.post(`users/verify_mobile`, {
        mobile_number: payload.mobileNumber,
        email: payload.email,
      });
      if (res.data.status === "success") {
        return res.data;
      } else {
        throw new Error(
          "An error occurred while verifying user's mobile number"
        );
      }
    };

    const response = await verify();
    return response;
  });

  return {
    verifyUserEmail,
    isVerifyingEmail: verifyUserEmail.isLoading,
    verifyUserMobile,
    isVerifyingMobile: verifyUserMobile.isLoading,
  };
};

export default useUser;
