import React, { useState, useEffect } from "react";
import AppointmentDetailsCard from "../../components/AppointmentDetailsCard";
import "../../App.css";
import useBookings from "../../hooks/useBookings";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Confirmation from "../../components/Confirmation";
import ReactGA from "react-ga4";

const Cancel = () => {
  const { bookingId, confirmationCode } = useParams();
  const navigate = useNavigate();
  const { scheduledBooking, cancelBooking, isFetching } = useBookings(
    bookingId,
    confirmationCode
  );

  const [matchingRule, setMatchingRule] = useState<any>();
  const [isCanceled, setIsCanceled] = useState<boolean>(false);

  useEffect(() => {
    setMatchingRule(scheduledBooking?.payment?.plan?.matching_rule);
  }, [scheduledBooking]);

  const handleButtonClick = async () => {
    if (isCanceled) {
      if (process.env.REACT_APP_GOOGLE_ANALYTICS) navigate("/");
    } else {
      try {
        const res = await cancelBooking?.mutateAsync();
        if (res?.status === "success") {
          // const analyticsPayload = JSON.stringify({
          // 	bookingDate: scheduledBooking?.booking_date,
          // 	bookingId: scheduledBooking?.onsched_booking_id,
          // 	timeZone: scheduledBooking.timezone,
          // 	promoCode: scheduledBooking?.payment?.applied_promo_code,
          // 	summary: scheduledBooking?.summary,
          // });

          //User Cancelled session -------------------------------------
          console.log("Session Canceled!");
          if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
            ReactGA.send({
              hitType: "pageview",
              page: `/cancel/${bookingId}/${confirmationCode}`,
              title: "Booking Canceled",
            });

            ReactGA.event({
              category: "Cancellation",
              action: "Session_Cancellation",
              value: bookingId ? parseInt(bookingId) : 0,
            });
          }
          setIsCanceled(true);
        }
      } catch (error) {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
          ReactGA.event({
            category: "Cancellation",
            action: "Cancellation_Error",
            label: `Session Cancellation Error: ${error}`,
          });
        }
        console.log(error);
      }
    }
  };

  return (
    <>
      {isFetching ? (
        <Spinner />
      ) : (
        <Confirmation
          smallText={isCanceled ? "" : "Sorry to see you go"}
          mainText={
            isCanceled
              ? "Your booking has been canceled."
              : matchingRule?.cancel_message ||
                "Please confirm that you would like to cancel"
          }
          subText={
            isCanceled
              ? "Thank you for choosing our platform for your virtual home care consultation."
              : matchingRule?.cancel_description
          }
          receiptCard={
            scheduledBooking?.booking_id && (
              <AppointmentDetailsCard
                booking_date={scheduledBooking?.booking_date}
                timezone={scheduledBooking.timezone}
                timezone_name={scheduledBooking.timezone_name}
                amount={scheduledBooking?.payment?.amount}
                appliedPromoCode={scheduledBooking?.payment?.applied_promo_code}
                paymentDiscount={
                  scheduledBooking?.payment?.applied_promo_code?.discount
                }
                taxType={scheduledBooking?.payment?.tax_type}
                taxAmount={scheduledBooking?.payment.tax_amount}
                totalAmount={scheduledBooking?.payment?.total_amount}
                onschedBookingId={scheduledBooking?.onsched_booking_id}
                summary={scheduledBooking?.summary}
                label={scheduledBooking?.label}
                icon={scheduledBooking?.payment?.plan.icon}
                buttonText={
                  isCanceled ? "Book your next VHC" : "Cancel Booking"
                }
                buttonVariant="contained"
                handleClick={handleButtonClick}
                isLoading={cancelBooking.isLoading}
                buttonDisabled={!matchingRule?.cancellation}
                primaryButton
              />
            )
          }
        />
      )}
    </>
  );
};

export default Cancel;
