import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useBookings from "../../hooks/useBookings";
import useOnSched from "../../hooks/useOnSched";
import Spinner from "../../components/Spinner";
import Confirmation from "../../components/Confirmation";
import AppointmentDetailsCard from "../../components/AppointmentDetailsCard";
import Scheduler from "../../components/Scheduler";
import toggleToast from "../../utils/messages";
import ReactGA from "react-ga4";
const Reschedule = () => {
  const { bookingId, confirmationCode } = useParams();
  const { scheduledBooking, isFetching, rescheduleBooking } = useBookings(
    bookingId,
    confirmationCode
  );
  const { deleteBooking } = useOnSched();
  const matchingRule = scheduledBooking?.matching_rule;

  const [isOnschedLoading, setIsOnschedLoading] = useState(true);
  const [availabilityReady, setAvailabilityReady] = useState(false);
  const [rescheduleDetails, setRescheduleDetails] = useState<any>({});
  const [view, setView] = useState(1);
  const isStart = view === 1;
  const isSelectDate = view === 2;
  const isConfirmChange = view === 3;
  const isRescheduled = view === 4;

  useEffect(() => {
    const handleUnload = async () => {
      await deleteBooking.mutateAsync(rescheduleDetails?.onsched_booking_id);
      console.log(
        `::::handleUnload, delete booking in IN state: ${rescheduleDetails?.onsched_booking_id}`
      );
    };

    window.addEventListener("message", (event) => {
      if (event.data === "handleUnload") {
        handleUnload();
      }
    });

    return () => {
      window.removeEventListener("message", handleUnload);
    };
  }, []);

  const handleButtonClick = async () => {
    if (view === 3) {
      try {
        //User has rescheduled a Booking -------------------------
        console.log("Rescheduled Booking!");
        if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
          ReactGA.send({
            hitType: "pageview",
            page: `/reschedule/${bookingId}/${confirmationCode}`,
            title: "Booking Reschedule",
          });

          ReactGA.event("Session_Rescheduled ", {
            category: "Reschedule",
            action: "Session_Reschedule",
            value: parseInt(rescheduleDetails?.onsched_booking_id),
          });
        }
        const response = await rescheduleBooking?.mutateAsync({
          start_date_time: rescheduleDetails?.start_date_time,
          end_date_time: rescheduleDetails?.end_date_time,
          reserved_booking_id: rescheduleDetails?.onsched_booking_id,
        });

        console.log("response", response);

        if (response?.status === "success") {
          console.log("reset setRescheduleDetails...");
          setRescheduleDetails({});
          console.log(
            `navigate to: /reschedule/${response.bookingId}/${response.confirmationCode}`
          );
          setView(4);
        } else {
          toggleToast("Unable to reschedule your session.", "error");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setView(2);
    }
  };

  useEffect(() => {
    if (!bookingId || !confirmationCode || !scheduledBooking?.payment?.plan)
      return;
    if (!isSelectDate || !availabilityReady) return;

    setIsOnschedLoading(true);

    const onsched = window.OnSched(
      process.env.REACT_APP_ONSCHED_ACCOUNT_ID,
      process.env.REACT_APP_ONSCHED_ENV
    );
    const elements = onsched.elements();
    const availabilityParams = {
      locationId: scheduledBooking?.payment?.plan?.onsched_location_id,
      serviceId: scheduledBooking?.payment?.plan?.onsched_service_id,
      resourceId: scheduledBooking?.payment?.plan?.onsched_resource_id,
      roundRobin: scheduledBooking?.payment?.plan?.onsched_round_robin,
      completeBooking: "IN",
      timezoneName: "America/Toronto",
    };
    const availabilityOptions = {
      loadTimesOnMount: true,
    };
    const availability = elements.create(
      "availability",
      availabilityParams,
      availabilityOptions
    );

    availability.mount("availability");

    const handleCalendarLoaded = () => {
      setIsOnschedLoading(false);
    };
    const handleAvailabilityClickTime = async (e: any) => {
      console.log("Time");
      setIsOnschedLoading(true);
      e.preventDefault();
    };
    const handleBookingConfirmation = async ({ detail }: any) => {
      console.log(":::::detail: ", detail);

      setRescheduleDetails({
        start_date_time: detail.startDateTime,
        end_date_time: detail.endDateTime,
        onsched_booking_id: detail.id,
        onsched_confirmation_code: detail.confirmationNumber,
      });
      setView(3);
    };

    const elAvailability = document.getElementById("availability");
    elAvailability?.addEventListener("getAvailability", handleCalendarLoaded);
    elAvailability?.addEventListener("clickTime", handleAvailabilityClickTime);
    elAvailability?.addEventListener(
      "bookingConfirmation",
      handleBookingConfirmation
    );

    return () => {
      elAvailability?.removeEventListener(
        "getAvailability",
        handleBookingConfirmation
      );
      elAvailability?.removeEventListener(
        "clickTime",
        handleAvailabilityClickTime
      );
      elAvailability?.removeEventListener(
        "bookingConfirmation",
        handleBookingConfirmation
      );
    };
  }, [view, scheduledBooking, availabilityReady]);

  return (
    <>
      {isFetching ? (
        <Spinner />
      ) : !scheduledBooking?.booking_id ? (
        <div className="loader-overlay">Error !</div>
      ) : (
        <>
          {isSelectDate ? (
            scheduledBooking?.payment && (
              <Scheduler
                pageTitle="Reschedule your VHC"
                planDescription={scheduledBooking?.payment?.plan.description}
                isLoading={isOnschedLoading}
                setAvailabilityReady={setAvailabilityReady}
              />
            )
          ) : (
            <Confirmation
              smallText={""}
              mainText={
                isConfirmChange
                  ? "Confirm rescheduled date and time."
                  : isRescheduled
                  ? "Reschedule complete !"
                  : matchingRule?.reschedule_message ||
                    "Please confirm that you would like to reschedule"
              }
              subText={
                isConfirmChange
                  ? "Click 'Confirm new Date' to make the change permanent."
                  : matchingRule?.reschedule_description
              }
              receiptCard={
                scheduledBooking?.booking_id && (
                  <AppointmentDetailsCard
                    primaryButton
                    booking_date={
                      rescheduleDetails.start_date_time ||
                      scheduledBooking?.booking_date
                    }
                    timezone={rescheduleDetails.timezone}
                    timezone_name={rescheduleDetails.timezone_name}
                    onschedBookingId={
                      rescheduleDetails.onsched_booking_id ||
                      scheduledBooking?.onsched_booking_id
                    }
                    amount={scheduledBooking?.payment?.amount}
                    appliedPromoCode={
                      scheduledBooking?.payment.applied_promo_code
                    }
                    paymentDiscount={
                      scheduledBooking?.payment?.applied_promo_code?.discount
                    }
                    taxType={scheduledBooking?.payment?.tax_type}
                    taxAmount={scheduledBooking?.payment?.tax_amount}
                    totalAmount={scheduledBooking?.payment.total_amount}
                    summary={scheduledBooking?.payment?.plan?.summary}
                    label={scheduledBooking?.payment?.plan?.label}
                    icon={scheduledBooking?.payment?.plan?.icon}
                    buttonText={
                      isConfirmChange
                        ? "Confirm new Date"
                        : isRescheduled
                        ? "Updated"
                        : "Reschedule your VHC"
                    }
                    buttonVariant="contained"
                    handleClick={handleButtonClick}
                    isLoading={rescheduleBooking.isLoading}
                    buttonDisabled={isRescheduled}
                  />
                )
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default Reschedule;
