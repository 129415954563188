import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import ReactGA from "react-ga4";
import { useStore } from "../../contexts/StoreContext";
import Scheduler from "../../components/Scheduler";
import { trackHotJarUserFlow } from "../../utils/hotjar";

const Schedule = () => {
  const { serviceId } = useParams();
  const navigate = useNavigate();
  const {
    createBooking,
    setSelectedPlan,
    setSelectedPlanId,
    selectedPlan,
    bookingPlans,
    promoCode,
    setAppliedPromoCode,
  } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [bookingCreated, setBookingCreated] = useState(false);
  const handleAvailabilityClickTime = useCallback(async (e: any) => {
    setIsLoading(true);
    e.preventDefault();
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGA.event({
        category: "Booking",
        action: "Plan_Selected",
        value: parseInt(!selectedPlan?.onsched_service_id ? 'n/a' : selectedPlan.onsched_service_id),
      });
      ReactGA.send({
        hitType: "pageview",
        page: `/schedule/${!selectedPlan?.onsched_service_id ? 'n/a' : selectedPlan.onsched_service_id}`,
        title: "Booking Date & Time",
      });
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (serviceId && (!selectedPlan || !selectedPlan?.onsched_service_id)) {
      const plan = bookingPlans?.find(
        (item) => `${item?.onsched_service_id}` === `${serviceId}`
      );

      if (plan) {
        console.log(`setSelectedPlan(plan_id: ${plan.plan_id})`);
        setSelectedPlan(JSON.parse(JSON.stringify(plan)));
      } else if (serviceId) {
        console.log(`setSelectedPlanId(${serviceId})`);
        setSelectedPlanId(serviceId);
      }
    }
  }, [bookingPlans]);

  useEffect(() => {
    if (!selectedPlan?.onsched_service_id) return;

    const onsched = window.OnSched(
      process.env.REACT_APP_ONSCHED_ACCOUNT_ID,
      process.env.REACT_APP_ONSCHED_ENV
    );
    const elements = onsched.elements();
    const availabilityParams = {
      locationId: selectedPlan?.onsched_location_id,
      serviceId: selectedPlan?.onsched_service_id,
      resourceId: selectedPlan?.onsched_resource_id,
      roundRobin: selectedPlan?.onsched_round_robin,
      completeBooking: "IN",
      timezoneName: "America/Toronto",
    };

    //TODO - use the client local timezone as default

    const availabilityOptions = {
      loadTimesOnMount: true,
    };
    const availability = elements.create(
      "availability",
      availabilityParams,
      availabilityOptions
    );
    availability.mount("availability");

    const handleBookingConfirmation = async ({ detail }: any) => {
      if (bookingCreated) return;

      try {
        console.log("booking details:", detail);

        setBookingCreated(true);
        const resource = detail?.resources.find(
          (item: { resourceEmail: string }) => {
            return item.resourceEmail === detail.resourceEmail;
          }
        );

        const payload = {
          inspector_email: detail.resourceEmail,
          onsched_booking_id: detail.id,
          onsched_resource_id: detail.resourceId,
          session_duration: detail.duration,
          booking_date: detail.startDateTime,
          timezone_name: detail.timezoneId,
          onsched_confirmation_code: detail.confirmationNumber,
          plan_id: selectedPlan.plan_id,
          promo_code: promoCode ? promoCode : null,
        };

        setSelectedPlan({
          ...selectedPlan,
          onschedBookingId: detail.id,
          booking_date: detail.startDateTime,
          timezone: detail.startDateTime.slice(-6),
          timezone_name: detail.timezoneId,
          serviceName: detail.serviceName,
          resourceName: resource.resourceName,
        });

        const analyticsPayload = JSON.stringify({
          plan_id: selectedPlan.plan_id,
          onsched_booking_id: detail.id,
          booking_date: detail.startDateTime,
        });

        trackHotJarUserFlow(detail.id, analyticsPayload);

        await createBooking?.mutateAsync(payload);
      } catch (err) {
        setBookingCreated(false);
        setIsLoading(false);
      }
    };

    const elAvailability = document.getElementById("availability");
    elAvailability?.addEventListener("clickTime", handleAvailabilityClickTime);
    elAvailability?.addEventListener(
      "bookingConfirmation",
      handleBookingConfirmation
    );

    const status = createBooking?.data?.status;
    if (status === "success" && bookingCreated) {
      console.log("::::createBooking", createBooking);
      console.log(
        "setAppliedPromoCode",
        createBooking?.data?.data?.applied_promo_code
      );

      setAppliedPromoCode(createBooking?.data?.data?.applied_promo_code);
      setIsLoading(false);
      navigate(`/booking/${selectedPlan?.onsched_service_id}`);
    }

    return () => {
      elAvailability?.removeEventListener(
        "clickTime",
        handleAvailabilityClickTime
      );
      elAvailability?.removeEventListener(
        "bookingConfirmation",
        handleBookingConfirmation
      );
    };
  }, [
    selectedPlan?.onsched_service_id,
    handleAvailabilityClickTime,
    bookingCreated,
    createBooking,
    setSelectedPlan,
    selectedPlan,
    navigate,
    promoCode,
    setAppliedPromoCode,
  ]);

  return (
    <Scheduler
      pageTitle="Schedule a Time"
      planDescription={selectedPlan?.description}
      isLoading={isLoading}
      progressMessage="Select a date & time that works for you"
    />
  );
};

export default Schedule;
