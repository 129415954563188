import React, { SetStateAction, useEffect, useState } from "react";
import {
  useElements,
  PaymentElement,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import DefaultButton from "../DefaultButton";
import { useStore } from "../../contexts/StoreContext";
import { useNavigate } from "react-router-dom";
import BusinessPolicyModal from "../BusinessPolicyModal";
import useOnSched from "../../hooks/useOnSched";
import toggleToast from "../../utils/messages";
import ReactGA from "react-ga4";
interface IProps {
  email: string;
  clientSecret: string;
  setShouldBlock: React.Dispatch<SetStateAction<boolean>>;
}

const CheckoutForm = ({ email, clientSecret, setShouldBlock }: IProps) => {
  const stripe = useStripe();
  const elements: any = useElements();
  const navigate = useNavigate();
  const { selectedPlan, appliedPromoCode } = useStore();
  const { reserveBooking } = useOnSched();
  const [paymentError, setPaymentError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = () => setIsModalOpen(false);
  const handleModalOpen = () => setIsModalOpen(true);

  useEffect(() => {
    console.log("Progressed To Payment!");
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGA.send({
        hitType: "pageview",
        page: `/booking/${selectedPlan.onsched_service_id}`,
        title: "Progressed to payment",
      });

      ReactGA.event({
        category: "Payment",
        action: "Progressed_To_Payment",
        value: parseInt(selectedPlan?.onschedBookingId),
      });
    }
  }, []);

  const handlePay = async () => {
    setIsLoading(true);
    setShouldBlock(false);
    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        toggleToast(submitError.message, "error");
      }
      const intent: any = await stripe?.confirmPayment({
        elements,
        clientSecret,
        redirect: "if_required",
      });

      console.log("Stripe intent:", intent);
      /* 
Sample intent object:
{
    "paymentIntent": {
        "id": "pi_3P45alAupyw0ipj70VSCL1VJ",
        "object": "payment_intent",
        "amount": 4519,
        "amount_details": {
            "tip": {}
        },
        "automatic_payment_methods": {
            "allow_redirects": "always",
            "enabled": true
        },
        "canceled_at": null,
        "cancellation_reason": null,
        "capture_method": "automatic",
        "client_secret": "pi_3P45alAupyw0ipj70VSCL1VJ_secret_UZP6O7zkaSmpdjjs7uciEqc7y",
        "confirmation_method": "automatic",
        "created": 1712772591,
        "currency": "cad",
        "description": "Amount Charged ($ 39.99) + Tax ($ 5.20)",
        "last_payment_error": null,
        "livemode": false,
        "next_action": null,
        "payment_method": "pm_1P45bIAupyw0ipj78qCKTGnv",
        "payment_method_configuration_details": {
            "id": "pmc_1On0jdAupyw0ipj757kXiRO3",
            "parent": null
        },
        "payment_method_types": [
            "card",
            "afterpay_clearpay",
            "klarna",
            "link"
        ],
        "processing": null,
        "receipt_email": "rcaniglia@gmail.com",
        "setup_future_usage": null,
        "shipping": null,
        "source": null,
        "status": "succeeded"
    }
}
*/
      const { error } = intent;
      if (error && error?.message) {
        return setPaymentError(error?.message);
      }
      await reserveBooking.mutateAsync({
        bookingId: selectedPlan?.onschedBookingId,
        email,
      });

      navigate(`/booking-confirmation/${selectedPlan?.onschedBookingId}`);
    } catch (error: any) {
      if (process.env.REACT_APP_GOOGLE_ANALYTICS)
        ReactGA.event({
          category: "Payment",
          action: "Booking_Payment_Error",
          label: `Error in payment: ${paymentError}`,
        });
      return setPaymentError(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <BusinessPolicyModal
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
      />

      <PaymentElement />
      {!!paymentError && (
        <Typography
          color="error"
          fontStyle="italic"
          mt="6"
          fontSize={"0.75rem"}
          fontWeight="bold"
        >
          {paymentError}
        </Typography>
      )}
      <Box
        mt={"2rem"}
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={isTermsChecked}
              onChange={() => setIsTermsChecked(!isTermsChecked)}
            />
          }
          label={
            <Typography fontSize="12px">
              I have read and acknowledge the{" "}
              <Link onClick={handleModalOpen} target="_blank" rel="noreferrer">
                cancellation & rescheduling policy
              </Link>
            </Typography>
          }
        />
        <DefaultButton
          variant="contained"
          disabled={!stripe || !elements || !isTermsChecked || isLoading}
          handleClick={handlePay}
          isLoading={isLoading}
        >
          Pay
        </DefaultButton>
      </Box>
    </Box>
  );
};

export default CheckoutForm;
