import { Typography, styled, CardActions, Button, Card } from "@mui/material";
import { COLORS } from "../../../utils/colors";

export const useServicesStyles = () => {
  return {
    cardHeader: {
      alignSelf: "center",
      fontWeight: 600,
      fontSize: "13px",
      paddingBottom: 1,
      textTransform: "uppercase",
    },
    divider: {
      width: "90%",
      borderColor: "rgb(163 163 163 / 10%)",
      height: "0.5px",
      marginBlock: "18px",
    },
    planIcon: {
      height: "45px",
      width: "45px",
      objectFit: "contain",
    },
    checkIcon: {
      height: "20px",
      wdith: "20px",
      objectFit: "contain",
    },
  };
};

export const StyledCard = styled(Card)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "35px",
  border: "solid 1px #ebedf1",
  borderRadius: "10px",
});

export const StyledCardDescription = styled(Typography)({
  fontWeight: 500,
  fontSize: "0.95rem",
  mb: 2,
  textAlign: "center",
  color: COLORS.PRIMARY,
  textTransform: "uppercase",
});

export const StyledCardPrice = styled(Typography)({
  fontWeight: 500,
  fontSize: "14px",
});

export const StyledCardActions = styled(CardActions)({
  margin: "0.5rem 0",
  display: "flex",
  justifyContent: "center",
});

export const StyledCardButton = styled(Button)({
  width: "90%",
  fontSize: "0.95rem",
  padding: "6px 15px",
  color: "#6d6d6d",
  borderColor: "#6d6d6d",
  ":hover": {
    border: `1px solid ${COLORS.PRIMARY}`,
    background: COLORS.PRIMARY,
    color: COLORS.WHITE,
  },
});

// export const StyledLink = styled(Link)({
//   color: COLORS.PRIMARY,
//   fontSize: "14px",
//   textAlign: "center",
//   cursor: "pointer",
//   fontWeight: 500,
//   transition: "all 2s hover",
//   ":hover": {
//     opacity: 0.8,
//   },
// });
