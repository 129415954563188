import React, { useEffect, useRef, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { StyledSectionHeader } from "./styles";
import ReactGA from "react-ga4";
import useUser from "../../hooks/useUser";
import useBookings from "../../hooks/useBookings";
import { useStore } from "../../contexts/StoreContext";

import DefaultButton from "../DefaultButton";
import { formatPhoneNumberToNANPFormat } from "../../utils/helpers";
import { HOMEOWNER_CONTACT_INFORMATION } from "../../utils/data";
import { COLORS } from "../../utils/colors";
import { Category } from "@mui/icons-material";

interface IFieldRefs {
  [key: string]: React.RefObject<HTMLInputElement>;
}
const HomeOwnerContact = ({
  values,
  handleChange,
  errors,
  setFieldValue,
  setFieldError,
}: any) => {
  const { isVerifyingMobile, verifyUserMobile } = useUser();
  const { isValidatingOTP, validateOTP } = useBookings();
  const {
    createdBooking,
    selectedPlan,
    isMobileVerified,
    setIsMobileVerified,
  } = useStore();
  const [isOTPSent, setIsOTPSent] = useState(false);
  const isLoading = isVerifyingMobile || isValidatingOTP;
  const fieldRefs = useRef<IFieldRefs>({});
  const [previousValue, setPreviousValue] = useState<string>("");

  const canVerifyMobile =
    values.firstname && values.lastname && values.mobile && values.mobile;

  useEffect(() => {
    fieldRefs.current = HOMEOWNER_CONTACT_INFORMATION.reduce((acc, field) => {
      acc[field.name] = React.createRef<HTMLInputElement>();
      return acc;
    }, {} as IFieldRefs);
  }, []);

  useEffect(() => {
    const firstErrorField = Object.keys(errors).find(
      (fieldName) => errors[fieldName]
    );
    if (firstErrorField && fieldRefs.current[firstErrorField]) {
      fieldRefs.current[firstErrorField].current?.focus();
    }
  }, [errors]);

  const handleDeletion = (
    previousValue: string,
    newValue: string,
    cursorPosition: number
  ): string => {
    let digitsOnly = newValue.replace(/\D/g, "");
    const isDeletingFormatChar =
      previousValue.length - newValue.length !== 1 ||
      (previousValue[cursorPosition] &&
        previousValue[cursorPosition].match(/\D/));

    if (isDeletingFormatChar) {
      let indexToDeleteFrom =
        previousValue.substring(0, cursorPosition).replace(/\D/g, "").length -
        1;
      digitsOnly = digitsOnly.substring(0, indexToDeleteFrom);
    }

    newValue = formatPhoneNumberToNANPFormat(digitsOnly);

    return newValue;
  };

  const handleMobileNumberChange = (name: string) => (e?: any) => {
    let newValue: string = e.target.value;
    const isDeletion = newValue.length < previousValue.length;
    const cursorPosition = e.target.selectionStart;

    if (isDeletion) {
      newValue = handleDeletion(previousValue, newValue, cursorPosition);
    } else {
      newValue = formatPhoneNumberToNANPFormat(newValue);
    }
    setFieldValue(name, newValue, false);
    setPreviousValue(newValue);
    if (errors.mobile) {
      setFieldError("mobile", "");
    }
  };

  const handleVerifyMobile = async () => {
    try {
      const response = await verifyUserMobile.mutateAsync({
        mobileNumber: values.mobile,
        email: values.email,
      });
      if (response.status === "success") {
        console.log("one time sent!");
        if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
          ReactGA.event({
            category: "Booking",
            action: "Mobile_Validation_Code_Sent",
          });
        }
        setIsOTPSent(true);
      }
    } catch (error) {
      if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
        ReactGA.event({
          category: "Booking",
          action: "Mobile_Validation_Error",
          label: `Mobile validation error: ${error}`,
        });
      }
      console.log(error);
    }
  };

  const handleValidateOTP = async () => {
    try {
      const response = await validateOTP.mutateAsync({
        verificationCode: values.verificationCode,
        email: values.email,
        bookingId: createdBooking.data.booking_id,
      });

      if (response.status === "success") {
        //setErrors({})

        //Mobile verified ---------------------------------
        console.log("mobile validated!");
        if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
          ReactGA.event({
            category: "Booking",
            action: "Mobile_Validation_Success",
            label: `Phone number validated`,
          });
        }
        setIsMobileVerified(true);
      }
    } catch (error) {
      if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
        ReactGA.event({
          category: "Booking",
          action: "Mobile_Validation_Error",
          label: `Mobile validation error: ${error}`,
        });
      }
      console.log(error);
    }
  };

  const handleValidateUser = async () =>
    isOTPSent ? await handleValidateOTP() : await handleVerifyMobile();

  return (
    <Grid item xs={12} justifyContent="flex-end">
      <Grid item xs={12} mb={4}>
        <StyledSectionHeader>HOMEOWNER CONTACT</StyledSectionHeader>
      </Grid>

      <Grid container spacing={4}>
        {HOMEOWNER_CONTACT_INFORMATION.map(({ label, name }) => {
          return (
            <Grid item xs={12} md={6} key={name}>
              <TextField
                fullWidth
                label={label}
                variant="outlined"
                value={values[name]}
                onChange={
                  name === "mobile"
                    ? handleMobileNumberChange(name)
                    : handleChange(name)
                }
                sx={{
                  "& > p": {
                    zIndex: 99,
                  },
                }}
                error={!!errors[name]}
                helperText={
                  errors.mobile ? (
                    <span style={{ color: COLORS.RED }}>{errors[name]}</span>
                  ) : (
                    name === "mobile" &&
                    `This needs be to the device number that you will attend your Virtual HomeCare consultation on.`
                    // (oneTimeSession
                    //   ? `This needs be to the device number that you will attend your Virtual HomeCare consultation on.`
                    //   : `This needs be to the device number that you will attend your Virtual HomeCare consultation on.
                    //     Please verify the mobile number.`)
                  )
                }
                inputRef={fieldRefs.current[name]}
              />
            </Grid>
          );
        })}
        {!selectedPlan?.optional_verify_mobile && !isMobileVerified && (
          <>
            {isOTPSent && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  autoFocus
                  label={"Enter Verification Code"}
                  variant="outlined"
                  value={values["verificationCode"]}
                  onChange={handleChange("verificationCode")}
                  helperText={
                    "OTP has been sent! Please check your SMS to copy the verification code. DO NOT CLOSE THIS TAB."
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <DefaultButton
                variant="contained"
                handleClick={handleValidateUser}
                disabled={
                  !values.email ||
                  isLoading ||
                  !canVerifyMobile ||
                  (isOTPSent && !values.verificationCode)
                }
                isLoading={isLoading}
              >
                {isOTPSent ? "Continue" : "Verify Mobile Number"}
              </DefaultButton>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default HomeOwnerContact;
