import React, { useEffect, useState } from "react";

import { Box, Button, Grid, Typography } from "@mui/material";
import { NewBooking } from "../../global/interfaces";
import SelectLogo from "../../icons/field_logo.png";
import CurrentTime from "../../icons/CurrentTimeIcon";
import FutureTime from "../../icons/FutureTimeIcon";
import UserIcon from "../../icons/UserIcon";
import EmailIcon from "../../icons/EmailIcon";
import PhoneIcon from "../../icons/PhoneNumberIcon";
import LocationPin from "../../icons/LocationPinIcon";
import DetailsIcon from "../../icons/DetailsIcon";
import { useNavigate } from "react-router-dom";
import { useImmediateBookingStyles } from "./styles/styles";
import { Title } from "@mui/icons-material";
import { useStore } from "../../contexts/StoreContext";
import useBookings from "../../hooks/useBookings";

interface BookSessionConfirmationProps {
  details?: NewBooking;
  isFuture: boolean;
}

const BookSessionConfirmation = ({
  details,
  isFuture,
}: BookSessionConfirmationProps) => {
  const styles = useImmediateBookingStyles();
  const navigate = useNavigate();
  const { selectedPlan } = useStore();
  const [dateTimeInfo, setDateTimeInfo] = useState({
    currentDateTime: "",
    futureDateTime: "",
  });

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const splitDateTime = (dateTimeString: string) => {
    const date = new Date(dateTimeString);
    const formattedDate = date.toISOString().split("T")[0];
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}`;

    setDate(formattedDate);
    setTime(formattedTime);
  };

  // const redirectToVHCPage = async () => {
  //   console.log("redirect vhc-pro...");
  //   navigate(`inspector/${selectedPlan.booking_session_code}`);
  // };

  // const getDate = () => {
  //   const timestamp = Date.now();
  //   const date = new Date(timestamp);
  //   const futureTimestamp = timestamp + 10 * 60 * 1000;
  //   const futureDate = new Date(futureTimestamp);
  //   const formattedFutureDate = futureDate.toLocaleTimeString([], {
  //     hour: "numeric",
  //     minute: "2-digit",
  //     hour12: true,
  //   });
  //   const formattedDate = date.toLocaleDateString();
  //   setDateTimeInfo({
  //     currentDateTime: formattedDate,
  //     futureDateTime: formattedFutureDate,
  //   });
  // };

  useEffect(() => {
    console.log(details?.bookingtime);
    if (details?.bookingtime) {
      splitDateTime(details?.bookingtime);
    }
  }, []);

  const print = () => {
    window.print();
  };

  return (
    <>
      <Box>
        <Box sx={styles.reciptHeader}>
          <Typography sx={!isFuture ? styles.title : styles.futureTitle}>
            Booking Complete
          </Typography>
          {/* {!isFuture && (
            <Button
              sx={styles.button}
              onClick={() => {
                redirectToVHCPage();
              }}
            >
              Enter VHC Session
            </Button>
          )} */}
        </Box>
        <Typography sx={styles.confirmation}>
          Confirmation#:{" "}
          {details?.bookingId ? details.bookingId.toString() : "Undefined"}
        </Typography>
        <Typography sx={styles.printConf} onClick={print}>
          Print booking confirmation
        </Typography>
        <Typography sx={styles.confirmation}>
          Your receipt has been emailed to the HomeOwner at{" "}
          {details?.email ? details.email : "Undefined"}
        </Typography>
        <Typography style={styles.subHeading}>SESSION DETAILS</Typography>
        <Grid
          container
          spacing={2}
          borderBottom={"1px solid lightgray"}
          paddingBottom={"1.5rem"}
          sx={styles.container}
        >
          <Grid
            item
            xs={5.5}
            sx={styles.gridItem}
            paddingTop={"0px !important"}
          >
            <Box sx={styles.information}>
              <img width={20} height={20} src={SelectLogo} alt="Select Logo" />
              <Typography sx={styles.bookingText}>
                {details?.sessiontype ? details.sessiontype : "Undefined"}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={5.5}
            sx={styles.gridItem}
            paddingTop={"0px !important"}
          >
            <Box sx={styles.information}>
              <CurrentTime />
              <Typography sx={styles.bookingText}>
                {date ? date : "Undefined"}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={5.5}
            sx={styles.gridItem}
            paddingTop={"0px !important"}
          >
            <Box sx={styles.information}></Box>
          </Grid>
          <Grid
            item
            xs={5.5}
            sx={styles.gridItem}
            paddingTop={"0px !important"}
          >
            <Box sx={styles.information}>
              <FutureTime />
              <Typography sx={styles.bookingText}>
                {time ? time : "Undefined"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography style={styles.subHeading}>PERSONAL INFORMATION</Typography>
        <Grid
          container
          spacing={2}
          borderBottom={"1px solid lightgray"}
          paddingBottom={"1.5rem"}
          sx={styles.container}
        >
          <Grid
            item
            xs={5.5}
            sx={styles.gridItem}
            paddingTop={"0px !important"}
          >
            <Box sx={styles.information}>
              <UserIcon />
              <Typography sx={styles.bookingText}>
                {details?.firstname && details?.lastname
                  ? `${details?.firstname} ${details?.lastname}`
                  : "Undefined"}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={5.5}
            sx={styles.gridItem}
            paddingTop={"0px !important"}
          >
            <Box sx={styles.information}></Box>
          </Grid>
          <Grid
            item
            xs={5.5}
            sx={styles.gridItem}
            paddingTop={"0px !important"}
          >
            <Box sx={styles.information}>
              <EmailIcon />
              <Typography sx={styles.bookingText}>
                {details?.email ? details.email : "Undefined"}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={5.5}
            sx={styles.gridItem}
            paddingTop={"0px !important"}
          >
            <Box sx={styles.information}>
              <PhoneIcon />
              <Typography sx={styles.bookingText}>
                {details?.mobile ? details.mobile : "Undefined"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {isFuture && (
          <>
            <Typography style={styles.subHeading}>HOME INFORMATION</Typography>
            <Grid
              container
              spacing={2}
              borderBottom={"0px !important"}
              paddingBottom={"1.5rem"}
              sx={styles.containerBottom}
            >
              <Grid
                item
                xs={5.5}
                sx={styles.gridItem}
                paddingTop={"0px !important"}
              >
                <Box sx={styles.information}>
                  <LocationPin />
                  <Typography sx={styles.bookingText}>
                    {details?.address ? details.address : "Undefined"}
                  </Typography>
                </Box>
                <Typography sx={styles.address}>
                  {details?.province ? details.province : "Undefined"}
                </Typography>
                <Typography sx={styles.address}>
                  {details?.city ? details.city : "Undefined"}
                </Typography>
                <Typography sx={styles.address}>
                  {details?.postalcode ? details.postalcode : "Undefined"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5.5}
                sx={styles.gridItem}
                paddingTop={"0px !important"}
              >
                <Box sx={styles.homeInfoText}>
                  <Typography sx={styles.sectionTitle}>
                    When was your home built?
                  </Typography>
                  <Typography sx={styles.information}>
                    {details?.yearbuilt ? details.yearbuilt : "Undefined"}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={5.5}
                sx={styles.gridItem}
                paddingTop={"0px !important"}
              >
                <Box sx={styles.information}></Box>
              </Grid>
              <Grid
                item
                xs={5.5}
                sx={styles.gridItem}
                paddingTop={"0px !important"}
              >
                <Box sx={styles.homeInfoText}>
                  <Typography sx={styles.sectionTitle}>
                    Purchase Date
                  </Typography>
                  <Typography sx={styles.homeInfoText}>
                    {details?.purchasedate ? details.purchasedate : "Undefined"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
        <Grid
          container
          spacing={2}
          borderBottom={"0px !important"}
          paddingBottom={"1.5rem"}
          sx={styles.containerBottom}
        >
          <Grid item xs={11} paddingTop={"1rem"}>
            <Box sx={styles.detailsIcon}>
              <DetailsIcon />
              <Typography sx={styles.bookingText}>
                {details?.description ? details.description : "Undefined"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BookSessionConfirmation;
