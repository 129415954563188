import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PageWrapper from "../../components/PageWrapper";
import { useStore } from "../../contexts/StoreContext";
import useBookings from "../../hooks/useBookings";
import { useSpotBookingStyles } from "./styles";

const getFormattedDate = (date: string) => {
  const pad = (num: number) => (num < 10 ? "0" : "") + num;

  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = pad(newDate.getMonth() + 1);
  const day = pad(newDate.getDate());

  const hours = pad(newDate.getHours());
  const minutes = pad(newDate.getMinutes());
  const seconds = pad(newDate.getSeconds());

  const timezoneOffset = -newDate.getTimezoneOffset();
  const offsetHours = pad(Math.floor(Math.abs(timezoneOffset) / 60));
  const offsetMinutes = pad(Math.abs(timezoneOffset) % 60);
  const offsetSign = timezoneOffset >= 0 ? "+" : "-";

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
};

const getCurrentTimezone = () => {
  const date = new Date();
  const timezoneOffset = -date.getTimezoneOffset();
  const pad = (num: number) => (num < 10 ? "0" : "") + num;

  const offsetHours = pad(Math.floor(Math.abs(timezoneOffset) / 60));
  const offsetMinutes = pad(Math.abs(timezoneOffset) % 60);
  const offsetSign = timezoneOffset >= 0 ? "+" : "-";

  const timezone = `${offsetSign}${offsetHours}:${offsetMinutes}`;

  return timezone;
};

const SpotBooking = () => {
  const { plan_id, auth_token } = useParams();
  const { createSpotBooking } = useBookings();
  const date = new Date();
  const [bookingTitle, setBookingTitle] = useState<string>("");
  const [sessionDate, setSessionDate] = useState(
    date.toISOString().slice(0, 16)
  );
  const {
    setRoot,
    setModalLayout,
    setSelectedPlan,
    setCreatedBooking,
    setBookingValues,
  } = useStore();

  const navigate = useNavigate();
  const styles = useSpotBookingStyles();

  const handleCreateSpotBooking = async () => {
    console.log(
      `handleCreateSpotBooking plan_id: ${plan_id} auth_token: ${auth_token}`
    );

    try {
      const booking_date = getFormattedDate(sessionDate);
      const bookingData = {
        bookingtime: booking_date,
        title: bookingTitle,
      };
      setBookingValues(bookingData);
      console.log("bookingData:", bookingData);
      const timezone_name = getCurrentTimezone();

      const resp = await createSpotBooking?.mutateAsync({
        plan_id,
        auth_token,
        title: bookingTitle,
        booking_date,
        timezone_name,
      });
      console.log("booking", resp);

      if (resp?.data) {
        setCreatedBooking({ data: resp.data });
        setSelectedPlan(resp.plan);

        const url = `/booking/${resp.data.booking_id}`;
        setRoot(window.location.origin + url);

        return navigate(url, { headless: 1 } as any);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setModalLayout("spot-booking");
    console.log("setModalLayout to spot-booking...");
  }, []);

  return (
    <PageWrapper>
      <>
        <Button
          disabled={!auth_token || !plan_id || bookingTitle.length === 0}
          sx={bookingTitle.length === 0 ? styles.buttonDisabled : styles.button}
          onClick={() => handleCreateSpotBooking()}
        >
          Continue booking
        </Button>
        <Typography style={styles.title}>
          Enter a Session Title and Date
        </Typography>
        <Box
          sx={{
            width: "100%",
            marginTop: "2rem",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <CircularProgress /> */}
          <Grid
            container
            spacing={2}
            sx={{
              marginBottom: "1.5rem",
              paddingBottom: "1.5rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item xs={12}>
              <FormControl variant="outlined" sx={styles.input} fullWidth>
                <TextField
                  required
                  label="Session Title"
                  variant="outlined"
                  name="bookingtitle"
                  value={bookingTitle}
                  onChange={(e) => {
                    setBookingTitle(e.target.value);
                  }}
                  fullWidth
                  margin="normal"
                />
              </FormControl>
            </Grid>
            <Grid item xs={5} sx={{ marginRight: 'auto'}}>
              <Box sx={ styles.input }>
                <TextField
                  label="Session Date & Time"
                  variant="outlined"
                  name="sessiondate"
                  defaultValue={date}
                  value={sessionDate}
                  onChange={(e) => setSessionDate(e.target.value)}
                  fullWidth
                  type="datetime-local"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    </PageWrapper>
  );
};

export default SpotBooking;
