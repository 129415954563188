import React, { useEffect, useRef } from "react";
import { Typography, Grid } from "@mui/material";
import { useStore } from "../../contexts/StoreContext";
import circularLogo from "../../utils/assets/circular-logo.png";
import { useConfirmationStyles } from "./styles";
import PageWrapper from "../PageWrapper";
import ReactGA from "react-ga4";

interface IProps {
  smallText?: string;
  mainText: string;
  subText?: string;
  receiptCard: any;
}

const Confirmation = ({
  smallText,
  mainText,
  subText,
  receiptCard,
}: IProps) => {
  const styles = useConfirmationStyles();
  const { selectedPlan, modalLayout, appliedPromoCode } = useStore();
  const hasRunOnce = useRef(false);

  useEffect(() => {
    if (hasRunOnce.current) return;
    if (Object.keys(appliedPromoCode).length !== 0) {
      console.log("Payment with promo!");
      if (process.env.REACT_APP_GOOGLE_ANALYTICS)
        ReactGA.event({
          category: "Payment",
          action: "Payment_With_Promo_Code",
          label: `Promo code: ${appliedPromoCode}`,
        });
      ReactGA.send({
        hitType: "pageview",
        page: `/booking-confirmation/${selectedPlan?.onschedBookingId}`,
        title: "Booking confirmed with promo",
      });
    }
    console.log("payment made!");
    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
      ReactGA.send({
        hitType: "pageview",
        page: `/booking-confirmation/${selectedPlan?.onschedBookingId}`,
        title: "Payment Made",
      });
      ReactGA.event({
        category: "Payment",
        action: "Payment_Made",
        value: parseInt(selectedPlan?.onschedBookingId),
      });
    }
    hasRunOnce.current = true;
  }, []);

  return (
    <PageWrapper>
      <>
      {!modalLayout && (
      <div className="confirmation-background" />
      )}
      <Grid 
        container 
        spacing={5} 
        justifyContent="center" 
        pt="2rem" >
        <Grid 
          item 
          xs={12}
          md={6} >
          {smallText && (
            <Typography 
              sx={styles.smallText} >
              {smallText}
            </Typography>
          )}

            <Typography variant="h4" sx={styles.mainText}>
              <img
                src={circularLogo}
                alt="booking complete"
                width="50px"
                className="inline-image"
              />
              {`  ${mainText}`}
            </Typography>

            {subText && (
              <Typography sx={styles.subText}>
                {subText.split("\\\\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            {receiptCard && receiptCard}
          </Grid>
        </Grid>
      </>
    </PageWrapper>
  );
};

export default Confirmation;
