import { useQuery, useMutation } from "react-query";
import { axiosOrder } from "../utils/axios-orders";

const usePlans = (planId?: string  | null) => {
  const axios = axiosOrder()

  const fetchPlans = async () => {
    console.log(`request ${process.env.REACT_APP_BASE_URL}plans/${planId}`)
    if (!planId) return

    const { data } = await axios.get(`plans/${planId}`)
    console.log(`request status: ${data.status}`)

    if (data.status === "success") {
      return data.data
    }

    throw new Error("Failed to fetch booking plans")
  }

  const {
    data: bookingPlans,
    isLoading,
    isError,
  } = useQuery(["plans", planId], fetchPlans, {
    refetchOnWindowFocus: false,
    enabled: !!planId,
    retry: 0,
  })

  return {
    isLoading,
    isError,
    bookingPlans,
  }
}

export default usePlans;
