import { opacity } from "html2canvas/dist/types/css/property-descriptors/opacity"
import { COLORS } from "../../../utils/colors"

export const useSpotBookingStyles = () => {
    return {
input: {
    '& .MuiOutlinedInput-root': {
  borderRadius: '50px',
  backgroundColor: 'white',
  fontSize: '15px',
  fontWeight: '500',
  height: '3rem',
  fontFamily: ['Montserrat', 'sans-serif'].join(','),
  borderColor: COLORS.BLACK,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.BLACK,
},
  '& fieldset': {
    borderRadius: '25px',
  },
  '&:hover fieldset': {
    borderColor: COLORS.DARK_GREY,
  },
  '&.Mui-focused fieldset': {
    borderColor: COLORS.PRIMARY,
  },
  '&.Mui-error fieldset': {
    borderColor: COLORS.RED,
  },
},
'& .MuiInputLabel-root': {
  display: 'flex',
  alignItems: 'center',
  fontWeight: '500',
  lineHeight: '11.5px',
  textAlign: 'center',
  fontSize: '15px',
  height: '2.5rem',
  top: '-10px',
  paddingBottom: '2.5px',
  color: COLORS.MEDIUM_GRAY,
},
'& .MuiInputLabel-root.Mui-focused': {
  color: COLORS.PRIMARY,
},
'&.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
  transform: 'translate(12px, 20%) scale(1)',
},
  },
  button: {
    fontWeight: 200,
    whiteSpace: 'nowrap',
    textTransform: 'none',
    backgroundColor: COLORS.PRIMARY,
    backgroundImage: 'none',
    fontSize: '15px !important',
    marginRight: '0',
    padding: '.3125rem 1rem',
    color: 'white !important',
    marginLeft: "auto",
    '&: Hover': {
        backgroundColor: COLORS.PRIMARY,
    }
},
buttonDisabled: {
    fontWeight: 200,
    whiteSpace: 'nowrap',
    textTransform: 'none',
    backgroundColor: COLORS.PRIMARY,
    backgroundImage: 'none',
    fontSize: '15px',
    opacity: '50%',
    marginRight: '0',
    padding: '.3125rem 1rem',
    color: 'white !important',
    marginLeft: "auto",
    '&: Hover': {
        backgroundColor: COLORS.PRIMARY,
    }
},
title: {
    fontWeight: 800,
    fontSize: '1.1rem',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2rem'
},
}
}