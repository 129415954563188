import React from "react";
import { AppBar, Toolbar, IconButton, styled } from "@mui/material";
import { Link } from "react-router-dom";

const StyledAppBar = styled(AppBar)({
  backgroundColor: "transparent",
});

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "nowrap",
});

const Header = () => {
  return (
    <StyledAppBar position="static" elevation={0}>
      <StyledToolbar>
        <IconButton
          size="large"
          component={Link}
          to="/"
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <img
            src="/logo/light_logo.png"
            alt="Your Logo"
            height="48px"
            width="auto"
          />
        </IconButton>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default Header;
