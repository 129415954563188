import React, { createContext, useContext, useEffect, useState } from "react";
import usePlans from "../hooks/usePlans";
import useBookings from "../hooks/useBookings";
import { NewBooking } from "../global/interfaces";

export interface IStore {
  root: string;
  setRoot: (url: string) => void;
  selectedPlanId: string | null;
  setSelectedPlanId: (state: any) => void;
  bookingPlans: any[];
  setSelectedPlan: (state: any) => void;
  selectedPlan?: any;
  createBooking?: Record<string, any>;
  createSpotBooking?: Record<string, any>;
  setCreatedBooking: (state: any) => void;
  createdBooking?: any;
  clientSecret?: string;
  isHeadless?: boolean;
  setIsHeadless: (state: any) => void;
  isMobileVerified?: boolean;
  setIsMobileVerified: (state: any) => void;
  modalLayout?: string;
  setModalLayout: (state: any) => void;
  promoCode?: string;
  setPromoCode: (state: any) => void;
  appliedPromoCode?: any;
  setAppliedPromoCode: (state: any) => void;
  userDetails?: any;
  setUserDetails: (state: any) => void;
  smallScreen?: boolean;
  setSmallScreen: (state: boolean) => void;
  bookingValues?: any;
  setBookingValues: (state: any) => void;
}

const defaultState: IStore = {
  root: "",
  setRoot: (url: string): void => {},
  selectedPlanId: "",
  setSelectedPlanId: (state: any): void => {},
  bookingPlans: [],
  setSelectedPlan: (state: any): void => {},
  setCreatedBooking: (state: any): void => {},
  setIsHeadless: (state: any): void => {},
  setIsMobileVerified: (state: any): void => {},
  setModalLayout: (state: any): void => {},
  setPromoCode: (state: any): void => {},
  setAppliedPromoCode: (state: any): void => {},
  setUserDetails: (state: any): void => {},
  smallScreen: false,
  setSmallScreen: (state: any): void => {},
  setBookingValues: (state: any): void => {},
};

const StoreContext = createContext(defaultState);

export function StoreProvider({ children }: { children: JSX.Element }) {
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const { bookingPlans } = usePlans(selectedPlanId);
  const { createBooking, createSpotBooking, createdBookingData } =
    useBookings();
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [createdBooking, setCreatedBooking] = useState(null);
  const [isHeadless, setIsHeadless] = useState(false);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [modalLayout, setModalLayout] = useState<string>("");
  const [promoCode, setPromoCode] = useState("");
  const [appliedPromoCode, setAppliedPromoCode] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [root, setRoot] = useState<string>(window.location.href);
  const [smallScreen, setSmallScreen] = useState<boolean>(false);
  const [bookingValues, setBookingValues] = useState<NewBooking | null>(null);

  useEffect(() => {
    if (createdBookingData) {
      setCreatedBooking(createdBookingData);
    }
  }, [createdBookingData]);

  return (
    <StoreContext.Provider
      value={{
        root,
        setRoot,
        selectedPlanId,
        setSelectedPlanId,
        bookingPlans,
        selectedPlan,
        setSelectedPlan,
        createBooking,
        createSpotBooking,
        createdBooking,
        setCreatedBooking,
        isHeadless,
        setIsHeadless,
        isMobileVerified,
        setIsMobileVerified,
        modalLayout,
        setModalLayout,
        promoCode,
        setPromoCode,
        userDetails,
        setUserDetails,
        appliedPromoCode,
        setAppliedPromoCode,
        smallScreen,
        setSmallScreen,
        bookingValues,
        setBookingValues,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
}

export function useStore(selectedPlanId?: string | null) {
  const context = useContext(StoreContext);

  useEffect(() => {
    if (selectedPlanId) {
      context.setSelectedPlanId(selectedPlanId);
    }
  }, [context]);

  return context;
}
