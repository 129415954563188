import React from 'react';

export default () => {
  return (
<svg width="21" height="13" viewBox="0 0 26 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.18 0h23.016a1.183 1.183 0 0 1 1.183 1.183v15.634A1.183 1.183 0 0 1 24.196 18l-23.017-.001a1.183 1.183 0 0 1-1.183-1.183l.001-15.633A1.183 1.183 0 0 1 1.18 0zm.71 1.182 9.878 8.677c.25.218.584.327.923.326.339-.001.676-.114.928-.335l9.867-8.668H1.89zm14.432 7.861 7.875 6.919V2.125l-7.875 6.918zm-6.376.784-7.957 6.99h21.397l-7.957-6.99-1.034.909a2.597 2.597 0 0 1-1.703.625 2.557 2.557 0 0 1-1.7-.617l-1.045-.918zm-8.768 6.135 7.876-6.918L1.18 2.125v13.837z" fill="#464E5F" fill-rule="nonzero"/>
</svg>

  )
}
