import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";

interface IProps {
  handleDeleteBooking: () => void;
}

const BookingCountdown = ({ handleDeleteBooking }: IProps) => {
  const TEN_MINUTES = 10 * 60;
  const [timeLeft, setTimeLeft] = useState<number>(TEN_MINUTES);
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime = `${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;

  useEffect(() => {
    if (timeLeft === 0) {
      handleDeleteBooking();
    }

    const timer = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [handleDeleteBooking, timeLeft]);

  return (
    <Typography align="center" fontWeight={500}>
      This booking will expire in {formattedTime}
    </Typography>
  );
};

export default BookingCountdown;
