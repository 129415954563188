import React from 'react';

export default () => {
  return (
<svg width="20" height="20" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <g fill="#464E5F" fill-rule="nonzero">
        <path d="M13.393 12.134V5.357a.895.895 0 0 0-.893-.893.895.895 0 0 0-.893.893V12.5c0 .241.098.464.26.634l3.57 3.571c.18.17.402.26.634.26a.89.89 0 0 0 .634-1.518l-3.312-3.313z"/>
        <path d="M12.5 0C5.607 0 0 5.607 0 12.5S5.607 25 12.5 25 25 19.393 25 12.5 19.393 0 12.5 0zm0 23.214c-5.91 0-10.714-4.803-10.714-10.714 0-5.91 4.803-10.714 10.714-10.714 5.91 0 10.714 4.803 10.714 10.714 0 5.91-4.803 10.714-10.714 10.714z"/>
    </g>
</svg>
  )
}