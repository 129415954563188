import React from 'react';

export default () => {
  return (
<svg width="16" height="21" viewBox="0 0 21 26" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.707 15.315a9.929 9.929 0 0 0-4.083-3.228A6.412 6.412 0 0 0 17.16 6.99 6.399 6.399 0 0 0 10.768.6 6.399 6.399 0 0 0 4.375 6.99c0 2.012.954 3.895 2.537 5.096a9.928 9.928 0 0 0-4.083 3.228A9.776 9.776 0 0 0 .9 21.17v3.384c0 .575.47 1.045 1.045 1.045H19.61a1.049 1.049 0 0 0 1.03-1.045V21.17a9.786 9.786 0 0 0-1.934-5.855zm-7.942-1.936c4.293 0 7.786 3.493 7.786 7.786v2.344H2.98v-2.344c0-4.293 3.492-7.786 7.785-7.786zm-4.308-6.39a4.314 4.314 0 0 1 4.308-4.308 4.314 4.314 0 0 1 4.308 4.308 4.314 4.314 0 0 1-4.308 4.308 4.314 4.314 0 0 1-4.308-4.308z" fill="#464E5F" fill-rule="nonzero"/>
</svg>

  )
}
