import React, { useEffect, useRef, useState } from "react";
import PageWrapper from "../PageWrapper";
import { Box, Typography } from "@mui/material";
import { COLORS } from "../../utils/colors";
import { useNavigate } from "react-router-dom";
import PageTitle from "../PageTitle";
import GoBack from "../GoBack";
import Spinner from "../Spinner";
import { useStore } from "../../contexts/StoreContext";
import ProgressIndicator from "../ProgressIndicator";
import { formatText } from "../../utils/helpers";

interface IProps {
  pageTitle: string;
  planDescription: string;
  isLoading?: boolean;
  progressMessage?: string;
  setAvailabilityReady?: Function;
}

const Scheduler = ({
  pageTitle,
  planDescription,
  isLoading,
  progressMessage,
  setAvailabilityReady,
}: IProps) => {
  const navigate = useNavigate();
  const { root, isHeadless: isHeadless, smallScreen } = useStore();
  const availabilityRef = useRef();

  useEffect(() => {
    setAvailabilityReady?.(true);
  }, []);

  return (
    <PageWrapper>
      <Box>
        {process.env.REACT_APP_ONSCHED_ENV !== "live" && (
          <Box
            position={"absolute"}
            bgcolor={COLORS.STRIPE_PURPLE}
            borderRadius={25}
            px={4}
            py={0.5}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            boxShadow={2}
            right={100}
            top={25}
          >
            <Typography color={COLORS.WHITE} fontWeight={"bold"}>
              SANDBOX
            </Typography>
          </Box>
        )}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {root !== window.location.href && (
            <Box
              style={
                isHeadless
                  ? { position: "absolute", top: "1rem" }
                  : { position: "absolute" }
              }
            >
              <GoBack handleClick={() => navigate(-1)} />
            </Box>
          )}
          <Box width="25%" />

          <Box width="60%">
            <Typography
              component="h3"
              align="center"
              color="text.primary"
              fontWeight="400"
              fontSize={smallScreen ? ".85rem" : "1rem"}
            >
              {formatText(planDescription)}
            </Typography>

            <PageTitle title={pageTitle} style={{ marginBottom: 0 }} />
          </Box>

          <Box
            width="25%"
            style={{ transform: `translateY(${smallScreen ? "-4.5rem" : 0})` }}
          >
            {progressMessage && !isHeadless && (
              <ProgressIndicator
                message={progressMessage}
                progress={30}
                step={1}
              />
            )}
          </Box>
        </Box>

        <Box marginTop={"30px"}>
          <Box ref={availabilityRef}>
            <div id="availability" />
          </Box>
        </Box>
        {isLoading && (
          <div className="loader-overlay">
            <Spinner />
          </div>
        )}
      </Box>
    </PageWrapper>
  );
};

export default Scheduler;
