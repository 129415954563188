import React from "react";
import { HOME_ADDRESS_INFORMATION } from "../../utils/data";
import { Grid, TextField } from "@mui/material";
import { StyledSectionHeader } from "./styles";
import { COLORS } from "../../utils/colors";

const HomeAddress = ({ values, errors, handleChange, optionalAddress }: any) => {
  return (
    <Grid item xs={12} mb="32px">
      <Grid item xs={12} mb="29px">
        <StyledSectionHeader>HOME ADDRESS</StyledSectionHeader>
      </Grid>
      <Grid container spacing={4}>
        {HOME_ADDRESS_INFORMATION.map(
          ({ label, name, size, maxLength, disabled }) => {
            return (
              <Grid item xs={12} md={size} key={name}>
                <TextField
                  fullWidth
                  label={optionalAddress ? label?.replace("*", "") : label}
                  variant="outlined"
                  value={values[name]}
                  onChange={handleChange(name)}
                  error={!!errors[name]}
                  inputProps={{
                    maxLength: maxLength && maxLength,
                  }}
                  disabled={disabled}
                  helperText={
                    errors[name] ? (
                      <span style={{ color: COLORS.RED }}>{errors[name]}</span>
                    ) : (
                      name === "country" &&
                      `This service is only available in Canada`
                    )
                  }
                />
              </Grid>
            );
          }
        )}
      </Grid>
    </Grid>
  );
};

export default HomeAddress;
