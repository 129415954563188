import { Box } from "@mui/material";
import React from "react";
import { useStore } from "../../contexts/StoreContext";

const PageWrapper = ({ children }: { children: JSX.Element }) => {
  const { isHeadless: isHeadless, smallScreen } = useStore();

  return (
    <Box
      padding={isHeadless ? "2rem" : smallScreen ? "0 2rem" : "2rem 4rem"}
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      {children}
    </Box>
  );
};

export default PageWrapper;
