import React from "react";
import { Typography } from "@mui/material";
import { useStore } from "../../contexts/StoreContext";

interface IProps {
  title: string;
  style?: { [key: string]: string | number };
}

const PageTitle = ({ title, style }: IProps) => {
  const { smallScreen } = useStore();
  return (
    <Typography
      align="center"
      color="text.primary"
      fontWeight="500"
      gutterBottom
      fontSize={smallScreen ? "1.1rem" : "2rem"}
      margin="0 1rem 2rem 1rem"
      sx={style}
    >
      {title}
    </Typography>
  );
};

export default PageTitle;
