import React, { ReactNode, useEffect, useState } from "react"
import {
  Grid,
  Typography,
  Paper,
  Divider,
  CircularProgress,
  useTheme,
  Box,
} from "@mui/material"
import { COLORS } from "../../utils/colors"
import {
  StyledBreakdownPrice,
  StyledBreakdownTitle,
  StyledButton,
  useAppointmentDetailsCardStyles,
} from "./styles"
import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc';
import { formatCurrency, formatText } from "../../utils/helpers"
dayjs.extend(utc);
interface IProps {
  booking_date: Date
  timezone?: string
  timezone_name?: string
  amount?: number
  taxAmount?: number
  totalAmount?: number
  paymentDiscount?: number
  appliedPromoCode?: any
  paymentType?: string
  onschedBookingId?: string
  buttonText?: string
  buttonVariant?: string
  buttonIcon?: any
  handleClick?: any
  isLoading?: boolean
  primaryButton?: boolean
  children?: ReactNode
  buttonDisabled?: boolean
  taxType?: string
  summary: string
  icon: string
  label: string
  email?: string
}

const AppointmentDetailsCard = ({
  booking_date,
  timezone,
  timezone_name,
  amount,
  taxAmount,
  totalAmount,
  paymentDiscount,
  appliedPromoCode,
  onschedBookingId,
  buttonText,
  buttonVariant,
  buttonIcon,
  handleClick,
  isLoading,
  primaryButton,
  children,
  buttonDisabled,
  taxType,
  summary,
  label,
  icon,
  email
}: IProps) => {
  const styles = useAppointmentDetailsCardStyles()

  const theme = useTheme()
  const date = !timezone ? dayjs(booking_date) : dayjs(booking_date).utcOffset(timezone)
  const today = dayjs().format("MMMM DD YYYY @ h:mm A")
  const contained = buttonVariant === "contained"
  const [planIcon, setPlanIcon] = useState("")

  const convertToBase64 = async (image_url: string) => {
    const url = image_url?.replace("/plans/", "/uploads/")
    try {
      const response = await fetch(url)
      const arrayBuffer = await response.arrayBuffer()
      const binary: any = new Uint8Array(arrayBuffer)
      const base64String = btoa(String.fromCharCode.apply(null, binary))

      setPlanIcon("data:image/webp;base64," + base64String)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    convertToBase64(icon);
  }, [icon]);

  return (
    <div id="receipt">
      <Paper 
        elevation={1} 
        sx={styles.wrapper} >
        <Box 
          sx={styles.icon}  >
          {planIcon && (
            <img
              style={
                styles.planIcon as React.CSSProperties
              }
              alt="Plan icon"
              src={planIcon}
            />
          )}

          <Box 
            sx={styles.title} >
            <Typography 
              fontWeight={600} 
              textAlign="left" >
              {label ? formatText(label) : ""}
            </Typography>

            <Typography 
              fontWeight={500} 
              textAlign="left" >
              {formatText(summary)}
            </Typography>
          </Box>
        </Box>

        <Typography
          m="24px 0 36px 0"
          fontWeight={600}
          fontSize=".95rem"
          textTransform="uppercase"
        >
          {`${date.format("MMMM DD YYYY")} @ ${date.format("h:mm A")}`}{!timezone_name ? '' : <React.Fragment> ({timezone_name})</React.Fragment>}
        </Typography>

        <Grid container spacing={2} fontSize="0.8rem">
          {appliedPromoCode?.valid && (
            <>
              <Grid item xs={6}>
                <StyledBreakdownTitle>
                  {appliedPromoCode?.valid
                    ? "PROMOCODE APPLIED"
                    : "INVALID PROMOCODE"}
                </StyledBreakdownTitle>
              </Grid>
              <Grid item xs={6} fontWeight={500}>
                <StyledBreakdownPrice>
                  {appliedPromoCode?.code?.toUpperCase()}
                </StyledBreakdownPrice>
              </Grid>
            </>
          )}
          {!!amount && (
            <>
              <Grid item xs={6}>
                <StyledBreakdownTitle>Subtotal</StyledBreakdownTitle>
              </Grid>
              <Grid item xs={6}>
                <StyledBreakdownPrice>
                  {formatCurrency(amount / 100)}
                </StyledBreakdownPrice>
              </Grid>
            </>
          )}

          {!!paymentDiscount && (
            <>
              <Grid item xs={6}>
                <StyledBreakdownTitle>Discount</StyledBreakdownTitle>
              </Grid>
              <Grid item xs={6}>
                <StyledBreakdownPrice>
                  {`${formatCurrency(paymentDiscount / -100)}`}
                </StyledBreakdownPrice>
              </Grid>
            </>
          )}

          {!!taxAmount && (
            <>
              <Grid item xs={6}>
                <StyledBreakdownTitle>{taxType || "HST"}</StyledBreakdownTitle>
              </Grid>
              <Grid item xs={6}>
                <StyledBreakdownPrice>
                  {formatCurrency(taxAmount / 100)}
                </StyledBreakdownPrice>
              </Grid>
            </>
          )}

          {totalAmount !== undefined ? (
            <>
              <Grid item xs={6}>
                <StyledBreakdownTitle sx={{ fontWeight: 700 }}>
                  GRAND TOTAL
                </StyledBreakdownTitle>
              </Grid>
              <Grid item xs={6}>
                <StyledBreakdownPrice sx={{ fontWeight: 700 }}>
                  {formatCurrency(totalAmount / 100)}
                </StyledBreakdownPrice>
              </Grid>
            </>
          ) : null}
        </Grid>

        {onschedBookingId && (
          <>
            <Divider sx={styles.divider} />
            <Typography fontWeight={600} color="#6d7783">
              Confirmation: #{onschedBookingId}
            </Typography>
          </>
        )}

        {primaryButton && (
          <StyledButton
            id="download-receipt"
            startIcon={buttonIcon && buttonIcon}
            onClick={handleClick}
            fullWidth
            disabled={buttonDisabled}
            sx={{
              bgcolor: buttonDisabled
                ? theme.palette.action.disabled
                : contained
                ? COLORS.PRIMARY
                : "transparent",
              border: buttonDisabled ? "none" : `1px solid ${COLORS.PRIMARY}`,
              color: contained ? "white" : COLORS.PRIMARY,
              ":hover": {
                color: COLORS.PRIMARY,
              },
              opacity: buttonDisabled ? 0.5 : 1,
            }}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size={30} />
            ) : (
              buttonText
            )}
          </StyledButton>
        )}

        {buttonText === "Download Receipt" && email && (
          <Typography
            mt="24px"
            fontSize="12px"
            color="#6d7783"
          >{`A confirmation email has been sent to ${email} for booking on ${today}`}</Typography>
        )}

        {children}
      </Paper>
    </div>
  )
}

export default AppointmentDetailsCard
