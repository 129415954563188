import React from "react";
import { Box, CardContent, Grid, Typography, Container } from "@mui/material";
import ReactGA from "react-ga4";
import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router-dom";
import {
  StyledCard,
  StyledCardActions,
  StyledCardButton,
  StyledCardPrice,
  useServicesStyles,
} from "./styles";
import { COLORS } from "../../utils/colors";
import PageTitle from "../../components/PageTitle";
import { useStore } from "../../contexts/StoreContext";
import checkIcon from "../../utils/assets/check-icon.webp";

if (process.env.REACT_APP_GOOGLE_ANALYTICS)
  ReactGA.send({ hitType: "pageview", page: "/", title: "Booking Services" });

const HOMEPORTER_ORGANIZATION_ID = 0;

const Services = () => {
  const navigate = useNavigate();
  const servicesStyles = useServicesStyles();
  const { bookingPlans, setSelectedPlan } = useStore("all");

  const handlePlanSelection = async (plan: {
    onsched_service_id: string;
    description: string;
  }) => {
    setSelectedPlan(plan);
    navigate(`/schedule/${plan.onsched_service_id}`);
  };

  return (
    <Box paddingBlock="1rem" width="100%">
      <Box
        width="50%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        mx="auto"
      >
        <Typography mb="1rem" fontWeight={500}>
          Book a service
        </Typography>
        <PageTitle
          title="Explore our VHC packages for ultimate comfort & peace of mind"
          style={{ lineHeight: 1.16 }}
        />
      </Box>

      <Container>
        {!bookingPlans ? (
          <Spinner />
        ) : (
          <Grid container spacing={5} justifyContent="center">
            {bookingPlans
              ?.filter(
                (plan) => plan.organization_id === HOMEPORTER_ORGANIZATION_ID
              )
              ?.map((plan) => {
                const {
                  bundle_limit,
                  expiry_in_days,
                  plan_id,
                  label,
                  icon,
                  amount,
                  features,
                  button_title,
                  status,
                } = plan;
                const oneTimeSession = !expiry_in_days && bundle_limit === 1;

                return (
                  <Grid item key={plan_id} xs={12} sm={12} md={4}>
                    <StyledCard>
                      <Box height="350px">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography sx={servicesStyles.cardHeader}>
                            {label}
                          </Typography>

                          <img
                            style={
                              servicesStyles.planIcon as React.CSSProperties
                            }
                            alt="Plan icon"
                            src={icon}
                          />
                        </Box>

                        <CardContent sx={{ p: 0 }}>
                          <StyledCardPrice>
                            <Typography
                              fontWeight={600}
                              fontSize="28px"
                              variant="caption"
                            >{`$${(
                              Math.round(amount) / 100 -
                              1
                            ).toFixed()} / `}</Typography>
                            {`${oneTimeSession ? `Session` : `Year`}`}
                          </StyledCardPrice>

                          <hr style={servicesStyles.divider} />

                          {features?.map((feature: string) => {
                            return (
                              <Box
                                key={feature}
                                display="flex"
                                flexDirection="column"
                              >
                                <Box display="flex" alignItems="center">
                                  <img
                                    src={checkIcon}
                                    alt="Check icon"
                                    style={
                                      servicesStyles.checkIcon as React.CSSProperties
                                    }
                                  />

                                  <Typography ml="28px" fontSize="13px">
                                    {feature}
                                  </Typography>
                                </Box>
                                <hr style={servicesStyles.divider} />
                              </Box>
                            );
                          })}
                        </CardContent>
                      </Box>

                      <StyledCardActions>
                        <StyledCardButton
                          onClick={() => handlePlanSelection(plan)}
                          fullWidth
                          disabled={!status}
                          sx={{
                            backgroundColor: status
                              ? COLORS.WHITE
                              : COLORS.LIGHT_GRAY,
                            border: status && `1px solid ${COLORS.DARK_GREY}`,
                          }}
                        >
                          {button_title.charAt(0).toUpperCase() +
                            button_title.slice(1)}
                        </StyledCardButton>
                      </StyledCardActions>
                    </StyledCard>
                  </Grid>
                );
              })}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default Services;
