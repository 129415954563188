import React from 'react';

export default () => {
  return (
<svg width="19" height="18" viewBox="0 0 24 23" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 4.429v17.617A.962.962 0 0 0 .96 23h16.114a.962.962 0 0 0 .959-.96v-6.747l-.45.78-2.39 1.5a.817.817 0 0 1-1.246-.722l.103-2.819 3.983-6.902V.96a.962.962 0 0 0-.96-.96H4.43v3.134A1.304 1.304 0 0 1 3.134 4.43H0zm14.698 10.174 2.065 1.191-1.928 1.209a.135.135 0 0 1-.147 0 .137.137 0 0 1-.073-.13l.083-2.273v.003zm2.554.702-2.373-1.358 4.82-8.349 2.371 1.37-4.818 8.337zm5.153-8.926.411-.715a1.37 1.37 0 1 0-2.37-1.37l-.414.715 2.373 1.37zM12.56 16.812a.335.335 0 0 1 0 .67H2.897a.335.335 0 0 1 0-.67h9.662zm.22-3.063a.335.335 0 0 1 0 .67H2.898a.335.335 0 0 1 0-.67h9.883zm2.357-7.752a.335.335 0 1 1 0 .67H7.71a.335.335 0 1 1 0-.67h7.426zm0 2.584a.335.335 0 1 1 0 .67H2.902a.335.335 0 0 1 0-.67h12.234zm-.96 2.584a.335.335 0 0 1 0 .67H2.898a.335.335 0 0 1 0-.67h11.28zM3.757.335v2.8a.62.62 0 0 1-.622.62H.335l3.42-3.42z" fill="#464E5F" fill-rule="nonzero"/>
</svg>

  )
}
