import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { COLORS } from "../../utils/colors";

const Spinner = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      top="50%"
      right="50%"
    >
      <CircularProgress sx={{ color: COLORS.PRIMARY }} />
    </Box>
  );
};

export default Spinner;
