import { Button, CircularProgress, styled } from "@mui/material";
import React from "react";
import { COLORS } from "../../utils/colors";

const StyledButton = styled(Button)({
  width: "12rem",
  height: "2.8rem",
  backgroundColor: COLORS.PRIMARY,
  border: `1px solid ${COLORS.PRIMARY}`,
  "&.MuiButton-outlined:hover": {
    border: `1px solid ${COLORS.PRIMARY}`,
  },
  "&:hover": {
    backgroundColor: COLORS.PRIMARY,
  },
  "&:disabled": {
    opacity: 0.4,
    backgroundColor: COLORS.PRIMARY,
    color: "white",
  },
});

const DefaultButton = ({
  children,
  disabled,
  handleClick,
  variant = "contained",
  isLoading,
  style,
  endIcon,
}: {
  children: JSX.Element | string;
  handleClick?: any;
  disabled?: boolean;
  variant?: any;
  style?: any;
  isLoading?: boolean;
  endIcon?: any;
}) => {
  const contained = variant === "contained";

  return (
    <StyledButton
      sx={{
        ...style,
        backgroundColor: contained ? COLORS.PRIMARY : "transparent",
        color: contained ? "white" : COLORS.PRIMARY,
      }}
      variant={variant}
      disabled={disabled}
      onClick={handleClick}
      disableElevation
      endIcon={!isLoading && endIcon}
    >
      {isLoading ? <CircularProgress size={30} color="inherit" /> : children}
    </StyledButton>
  );
};

export default DefaultButton;
