export const COLORS = {
  BACKGROUND: "#1A2660", // '#161C5A',
  TEXT: "#0E254D",
  PRIMARY: "#FF6E00",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  TRANSPARENT: "transparent",
  RED_LIGHT: "#F08080",
  RED: "#FF0000",
  DARK_GREY: "#2F2F2F",
  LIGHT_GRAY: "#edeff0",
  MEDIUM_GRAY: "#8A94A6",
  STRIPE_PURPLE: "#635BFF",
  TOAST_SUCCESS: "#FF6E00",
  TOAST_ERROR: "#fc7777",
};
