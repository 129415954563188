import {
  Modal,
  Backdrop,
  styled,
  Box,
  Typography,
  Fade,
  useTheme,
} from "@mui/material";
import React from "react";
import DefaultButton from "../DefaultButton";

const StyledModal = styled(Box)({
  width: "400px",
  borderRadius: "10px",
  boxShadow: "0 19px 25px 0 rgba(210, 210, 210, 0.1)",
  backgroundColor: "#fff",
  padding: "20px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

interface IProps {
  isModalOpen: boolean;
  handleModalClose: () => void;
  handleConfirm: () => void;
  handleCancel: () => void;
}

const BeforeUnloadModal = ({
  isModalOpen,
  handleModalClose,
  handleConfirm,
  handleCancel,
}: IProps) => {
  const theme = useTheme();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      onClose={handleModalClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 300,
        },
      }}
    >
      <Fade in={isModalOpen}>
        <StyledModal>
          <Typography fontWeight={600} fontSize="17px" textAlign="center">
            Are you sure you want to leave? Progress will be lost
          </Typography>

          <Box
            borderBottom={`1px solid ${theme.palette.divider}`}
            width="70%"
            my="24px"
          />

          <Box display="flex" justifyContent="space-between" width="100%">
            <DefaultButton
              variant="outlined"
              handleClick={() => {
                handleCancel();
                handleModalClose();
              }}
              style={{ marginRight: "6px" }}
            >
              Cancel
            </DefaultButton>

            <DefaultButton
              style={{ marginLeft: "6px" }}
              handleClick={handleConfirm}
            >
              Confirm
            </DefaultButton>
          </Box>
        </StyledModal>
      </Fade>
    </Modal>
  );
};

export default BeforeUnloadModal;
