import { toast, Slide } from "react-toastify"
import { COLORS } from "../colors"

const toggleToast = (message: string, status: "success" | "error") => {
  toast(message, {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: status,
    transition: Slide,
    icon: false,
    style: {
      background:
        status === "success" ? COLORS.TOAST_SUCCESS : COLORS.TOAST_ERROR,
      color: "white",
      fontSize: "15px",
      fontFamily: "Montserrat",
    },
  })
}

export default toggleToast