import { styled, Button, Typography } from "@mui/material";
import { COLORS } from "../../../utils/colors";

export const useConfirmationStyles = () => {
  return {
    wrapper: { 
      justifyContent: "space-around",
      alignItems: "center",
      padding: "2rem",
    },
    left: {
      marginBottom: "auto",
    },
    smallText: {
      fontWeight: 500,
      fontSize: ".8125rem",
    },
    mainText: { 
      alignSelf: "start",
      fontWeight: "bold",
    },
    subText: {
      fontWeight: 500, 
      marginTop: "1.875rem",
    }
  }
}