import { useMutation } from "react-query";
import { axiosOrder } from "../utils/axios-orders";

const useOnSched = () => {
  const deleteBooking = useMutation(async (bookingId?: string) => {
    const axios = axiosOrder();
    try {
      if (bookingId) {
        await axios.delete(`onsched/bookings/${bookingId}`);
      }
    } catch (error) {
      console.log(error);
    }
  });

  const reserveBooking = useMutation(
    async ({ bookingId, email }: { bookingId: string; email: string }) => {
      const axios = axiosOrder();
      try {
        if (bookingId) {
          await axios.put(`onsched/bookings/reserve/${bookingId}`, {
            email: email,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  );

  return {
    deleteBooking,
    reserveBooking,
    isDeletingService: deleteBooking.isLoading,
  };
};

export default useOnSched;
