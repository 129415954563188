import { styled, Button, Typography } from "@mui/material";
import { COLORS } from "../../../utils/colors";

export const useAppointmentDetailsCardStyles = () => {
  return {
    wrapper: { 
      padding: "2rem 2rem", 
      textAlign: "center",
    },
    icon: {
      marginBottom: 'auto',
      display: "flex" ,
      alignItems: "center",
    },
    title: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "auto",
    },
    divider: { 
      marginBlock: "2.5rem" 
    },
    planIcon: {
      height: "3.125rem",
      width: "3.125rem",
      objectFit: "contain",
      marginRight: "1.5625rem",
    },
  };
};

export const StyledButton = styled(Button)({
  color: COLORS.PRIMARY,
  marginTop: "1.5rem",
  height: "2.5rem",
});

export const StyledBreakdownTitle = styled(Typography)({
  textAlign: "left",
  fontWeight: 500,
});

export const StyledBreakdownPrice = styled(Typography)({
  textAlign: "right",
  fontWeight: 500,
});
