import { color } from "html2canvas/dist/types/css/types/color"
import { COLORS } from "../../../utils/colors"


export const useImmediateBookingStyles = () => {
    return {
        modal: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            height: '100%',
            minWidth: '40%',
            minHeight: '40%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: '4',
            borderRadius: '1rem',
            padding: '2rem',
            paddingTop: '1rem',
            paddingBottom: '2.5rem'
        },
        reciptModal: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: '700px',
            width: 'auto',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '1rem',
            padding: '4rem',
            paddingTop: '1rem',
            paddingBottom: '6.5rem'
        },
        bookingModal: {
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '700px',
          height: '800px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: '1rem',
          padding: '4rem',
          paddingTop: '1rem',
          paddingBottom: '6.5rem'

        },
        detailsInput: {
          '& .MuiOutlinedInput-root': {
            height: '5.5rem',
            borderRadius: '15px',
            fontSize: '11px',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: COLORS.BLACK,
          },
          },
          '& .MuiInputLabel-root': {
            fontWeight: '500',
            lineHeight: '10px',
            fontSize: '11px',
            color: COLORS.MEDIUM_GRAY,
          },
        },

        input: {
          '& .MuiOutlinedInput-root': {
        borderRadius: '50px',
        backgroundColor: 'white',
        fontSize: '11px',
        fontWeight: '500',
        height: '2.3rem',
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        borderColor: COLORS.BLACK,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.BLACK,
      },
        '& fieldset': {
          borderRadius: '25px',
        },
        '&:hover fieldset': {
          borderColor: COLORS.DARK_GREY,
        },
        '&.Mui-focused fieldset': {
          borderColor: COLORS.PRIMARY,
        },
        '&.Mui-error fieldset': {
          borderColor: COLORS.RED,
        },
      },
      '& .MuiInputLabel-root': {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '500',
        lineHeight: '11.5px',
        textAlign: 'center',
        fontSize: '11px',
        height: '29px',
        top: '-9px',
        paddingBottom: '2.5px',
        color: COLORS.MEDIUM_GRAY,
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: COLORS.PRIMARY,
      },
      '&.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
        transform: 'translate(12px, 20%) scale(1)',
      },
        },

        selectInput: {
          '& .MuiOutlinedInput-root': {
        borderRadius: '50px',
        backgroundColor: 'white',
        fontSize: '11px',
        fontWeight: '500',
        height: '2.3rem',
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        borderColor: COLORS.BLACK,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.BLACK,
      },
        '& fieldset': {
          borderRadius: '25px',
        },
        '&:hover fieldset': {
          borderColor: COLORS.DARK_GREY,
        },
        '&.Mui-focused fieldset': {
          borderColor: COLORS.PRIMARY,
        },
        '&.Mui-error fieldset': {
          borderColor: COLORS.RED,
        },
      },
      '& .MuiInputLabel-root': {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '500',
        lineHeight: '11.5px',
        textAlign: 'center',
        fontSize: '11px',
        height: '29px',
        paddingBottom: '2.5px',
        color: COLORS.MEDIUM_GRAY,
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: COLORS.PRIMARY,
      },
      '&.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
        transform: 'translate(12px, 20%) scale(1)',
      },
        },



        container: {
          borderBottom: '1px solid lightgrey',
          marginBottom: '1rem',
          paddingBottom: '1.2rem',
          display: 'flex',
          justifyContent: 'center'
        },
        containerBottom: {
          borderBottom: '0px solid lightgrey',
          marginBottom: '0rem',
          paddingBottom: '0rem',
          display: 'flex',
          justifyContent: 'center'
        },
        title: {
            fontWeight: 800,
            fontSize: '1.1rem',
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        futureTitle: {
          fontWeight: 800,
          fontSize: '1.1rem',
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
        },
        header: {
            display: 'flex', 
            justifyContent: 'center', 
            height: '3rem', 
            alignItems: 'center',
            marginBottom: '1.5rem',
            paddingLeft: '9rem'
        },
        reciptHeader: {
          display: 'flex', 
          justifyContent: 'center', 
          height: '3rem', 
          alignItems: 'center',
      },
        button: {
            fontWeight: 200,
            whiteSpace: 'nowrap',
            backgroundColor: COLORS.PRIMARY,
            fontSize: '0.75rem',
            marginRight: '0',
            padding: '.3125rem 1rem',
            color: '#fff',
            '&: hover': {
            backgroundColor: COLORS.PRIMARY,
            }
        },
        subHeading: {
          fontSize: '11px',
          paddingBottom: '1.5rem',
          paddingLeft: '1rem',
          color: COLORS.BLACK,
          fontWeight: 500
        },
        bookingText: {
          display: 'flex',
          alignItems: 'center',
          fontSize: '12.5px',
          marginLeft: '0.6rem'
        },
        gridItem: {
          paddingBottom: '0.8rem'
        },
        confirmation: {
          textAlign: 'center',
          fontSize: '11px',
          marginBottom: '1.8rem',
        },
        printConf: {
          textAlign: 'center',
          fontSize: '10px',
          marginBottom: '0.5rem',
          textDecoration: 'underline',
          cursor: 'pointer'
        },
        information: {
          fontSize: '12px',
          display:'flex',
          alignItems: 'center',
          color: COLORS.BLACK,
          fontWeight: 400
        },
        homeInfoText: {
          fontSize: '12px',
        },
        sectionTitle: {
          fontWeight: '500',
          fontSize: '12px',
        },
        iconSpace: {
          Width: '100%',
          marginLeft: '1rem',
          fontSize: '15px'
        },
        bookingIcon: {
          marginRight: '0.6rem',
          },
        detailsIcon: {
          fontSize: '12px',
          color: COLORS.BLACK,
          fontWeight: 400,
          display: 'flex',
          alignItems: 'flexStart'
        },
        address: {
          paddingLeft: '1.5rem',
          fontSize: '12px',
          color: COLORS.BLACK
        },


  }
  }