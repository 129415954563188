import React, { useCallback, useEffect, useState } from "react";
import AppointmentDetailsCard from "../../components/AppointmentDetailsCard";
import PersonalInfo from "../../components/PersonalInfo";
import { useBeforeUnload, useBlocker, useNavigate } from "react-router-dom";
import { Grid, Paper, Box, Typography } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import GoBack from "../../components/GoBack";
import PageWrapper from "../../components/PageWrapper";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactGA from "react-ga4";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useStore } from "../../contexts/StoreContext";
import useOnSched from "../../hooks/useOnSched";
import useBookings from "../../hooks/useBookings";
import CheckoutForm from "../../components/CheckoutForm";
import { COLORS } from "../../utils/colors";
import Spinner from "../../components/Spinner";
import BookingCountdown from "../../components/BookingCountdown";
import BeforeUnloadModal from "../../components/BeforeUnloadModal";
import { trackHotJarUserFlow } from "../../utils/hotjar";
import ProgressIndicator from "../../components/ProgressIndicator";
import { formatPhoneNumberToE164 } from "../../utils/helpers";
import { NewBooking } from "../../global/interfaces";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

const Booking = () => {
  const navigate = useNavigate();
  const {
    root,
    modalLayout,
    isHeadless,
    selectedPlan,
    setSelectedPlan,
    createdBooking,
    promoCode: savedPromoCode,
    setPromoCode,
    userDetails,
    setUserDetails,
    setAppliedPromoCode,
    appliedPromoCode,
    smallScreen,
    setBookingValues,
  } = useStore();

  const { deleteBooking } = useOnSched();
  const [clientSecret, setClientSecret] = useState<any>("");
  const { updateBooking } = useBookings();
  const { bookingValues } = useStore();

console.log(':::bookingValues:', bookingValues)

  const [isUpdatingBooking, setIsUpdatingBooking] = useState(false);
  const [shouldBlock, setShouldBlock] = useState(true);
  const blocker = useBlocker(() => shouldBlock);
  const optionalAddress = selectedPlan?.optional_address;
  const {
    values,
    handleChange,
    errors,
    handleSubmit,
    isValid,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      address: "",
      city: "",
      postalCode: "",
      province: "",
      country: "Canada",
      yearBuilt: "",
      purchaseDate: "",
      promoCode: !savedPromoCode ? "" : savedPromoCode,
      verificationCode: "",
      description: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("First Name is required"),
      lastname: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      mobile: Yup.string()
        .matches(
          /^\+1\s*\(?(\d{3})\)?\s*(\d{3})\s*-\s*(\d{4})$/,
          'Mobile number must start with "+1" and have 11 digits'
        )
        .required("Mobile number is required"),
      address: optionalAddress
        ? Yup.string()
        : Yup.string().required("Address is required"),
      city: optionalAddress
        ? Yup.string()
        : Yup.string().required("City is required"),
      postalCode: optionalAddress
        ? Yup.string()
        : Yup.string().required("Postal code is required").max(8),
      province: optionalAddress
        ? Yup.string()
        : Yup.string().required("Province is required"),
      country: optionalAddress
        ? Yup.string()
        : Yup.string().required("Country is required"),
      yearBuilt: Yup.string(),
      purchaseDate: Yup.string(),
      promoCode: Yup.string().max(50),
      verificationCode: Yup.string(),
      description: Yup.string(),
    }),
    onSubmit: async () => {
      setIsUpdatingBooking(true);
      const {
        email,
        firstname,
        lastname,
        mobile,
        address,
        postalCode,
        city,
        province,
        // country,
        description,
        yearBuilt,
        purchaseDate,
        promoCode,
      } = values;

      const payload = {
        booking_id: createdBooking?.data.booking_id,
        body: {
          email: email.toLowerCase(),
          firstname,
          lastname,
          mobile_number: formatPhoneNumberToE164(mobile),
          address,
          postal_code: postalCode.replace(/\s/g, ""),
          city,
          province,
          country_code: "CA", // country,
          promo_code: promoCode,
          description:
            description +
            `\n\n${yearBuilt ? `Year Built: ${yearBuilt}` : ""}\n\n${
              purchaseDate ? `Purchase Date: ${purchaseDate}` : ""
            }`,
          plan_id: selectedPlan?.plan_id,
          booking_secret: createdBooking?.data.booking_secret,
        },
      };
      console.log('payload', payload)
      console.log('createdBooking:', createdBooking)
      const analyticsPayload = JSON.stringify({
        name: `${firstname}-${lastname}`,
        email: email.toLowerCase(),
        onsched_booking_id: selectedPlan?.onschedBookingId,
        timezone: selectedPlan?.timezone,
        summary: selectedPlan?.summary,
        promoCode: promoCode,
      });
console.log('analyticsPayload',analyticsPayload)

      if (process.env.REACT_APP_GOOGLE_ANALYTICS)
        ReactGA.event("generate_lead", {
          app_name: "booking",
          screen_name: "schedule",
          value: analyticsPayload,
        });

console.log('done analytics...')

      trackHotJarUserFlow(selectedPlan?.onschedBookingId, analyticsPayload);
      const bookingData: NewBooking = {
        bookingId: createdBooking?.data.booking_id,
        bookingtime: createdBooking?.data.booking_date,
        sessiontype: "",
        title: bookingValues?.title,
        firstname: firstname,
        lastname: lastname,
        email: email,
        mobile: mobile,
        address: address,
        city: city,
        postalcode: postalCode,
        province: province,
        country: "Canada",
        description: description,
      };

console.log('bookingData', bookingData)
      setBookingValues(bookingData);
      console.log(bookingData);
      try {
        await updateBooking?.mutateAsync(payload);

console.log('createdBooking', createdBooking)

        setUserDetails({ ...userDetails, email });
      } catch (error) {
        console.log(error);
      } finally {
        setIsUpdatingBooking(false);
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const appearance = {
    theme: "stripe",
  };
  const options: any = {
    clientSecret,
    appearance,
  };

  const handleCloseModal = () => blocker.reset && blocker.reset();

  const handleDeleteBooking = async () => {
    console.log("::::handleDeleteBooking selectedPlan", selectedPlan);

    setShouldBlock(false);
    await deleteBooking.mutateAsync(selectedPlan?.onschedBookingId);
    navigate(-1);
  };

  const handleBackClick = async () => {
    console.log("::::handleBackClick selectedPlan", selectedPlan);

    if (clientSecret) {
      updateBooking?.reset();
      setClientSecret("");
    } else {
      setShouldBlock(false);
      handleDeleteBooking();
    }
  };

  const handleConfirm = async () => {
    console.log("::::handleConfirm selectedPlan", selectedPlan);

    setShouldBlock(false);
    handleCloseModal();
    await deleteBooking.mutateAsync(selectedPlan?.onschedBookingId);
    blocker?.location && navigate(blocker.location.pathname);
  };

  useBeforeUnload(
    useCallback((event) => {
      event.preventDefault();
    }, []),
    { capture: true }
  );

  useEffect(() => {
    const handleUnload = () => {
      window.localStorage.setItem(
        "local_booking_id",
        selectedPlan?.onschedBookingId
      );
    };

    window.addEventListener("unload", handleUnload);

    return () => window.removeEventListener("unload", handleUnload);
  }, [selectedPlan?.onschedBookingId]);

  useEffect(() => {
    const data = updateBooking?.data?.data;
    if (!data?.payment_type) {
      console.log("Time Selected!");
      if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
        ReactGA.event({
          category: "Booking",
          action: "Booking_Time_Selected",
          value: Date.parse(selectedPlan?.booking_date),
        });
      }
    }
  }, []);

  useEffect(() => {
    if (!createdBooking?.data.booking_secret) {
      setShouldBlock(false);
      return navigate(`/`);
    }

    const data = updateBooking?.data?.data;
    if (data?.applied_promo_code) {
      setAppliedPromoCode(data?.applied_promo_code);
      setPromoCode(data?.applied_promo_code.code);
    }

    console.log("booking useEffect:");
    console.log("  updateBooking?.data?.data:", data);
    console.log("  selectedPlan:", selectedPlan);

    if (
      data?.clientSecret &&
      !clientSecret &&
      data?.payment_type === "stripe"
    ) {
      setClientSecret(data?.clientSecret);
      setSelectedPlan({
        ...selectedPlan,
        tax_amount: data?.tax_amount,
        tax_type: data?.tax_type,
        total_amount: data?.total_amount,
      });
    } else if (data?.payment_type === "promo_code") {
      setShouldBlock(false);
      setSelectedPlan({
        ...selectedPlan,
        payment_type: data?.payment_type,
        total_amount: data?.total_amount,
        tax_amount: "",
        tax_type: "",
      });

      return navigate(
        `/booking-confirmation/${selectedPlan?.onschedBookingId}`
      );
    } else if (data?.payment_type === "existing_payment") {
      setShouldBlock(false);
      setSelectedPlan({
        ...selectedPlan,
        paymentType: data?.payment_type,
        totalAmount: data?.total_amount,
      });
      return navigate(
        `/booking-confirmation/${selectedPlan?.onschedBookingId}`
      );
    }
  }, [
    selectedPlan,
    setSelectedPlan,
    updateBooking?.data?.data,
    clientSecret,
    navigate,
    createdBooking?.data.booking_secret,
  ]);

  return (
    <PageWrapper>
      {deleteBooking.isLoading ? (
        <Spinner />
      ) : (
        <>
          <BeforeUnloadModal
            handleModalClose={handleCloseModal}
            isModalOpen={blocker.state === "blocked"}
            handleConfirm={handleConfirm}
            handleCancel={handleCloseModal}
          />

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            {root !== window.location.href && (
              <Box
                style={
                  isHeadless
                    ? { position: "absolute", top: "1rem" }
                    : { position: "absolute" }
                }
              >
                <GoBack handleClick={handleBackClick} />
              </Box>
            )}
            <Box width="25%" />

            <Box width="50%">
              {modalLayout !== "spot-booking" && (
                <BookingCountdown handleDeleteBooking={handleDeleteBooking} />
              )}
              <PageTitle
                title={
                  modalLayout === "spot-booking"
                    ? "Book a VHC Session"
                    : "Tell us about your Home"
                }
                style={{ marginBottom: 0 }}
              />
            </Box>

            <Box
              width="25%"
              style={{
                transform: `translateY(${smallScreen ? "-4.5rem" : 0})`,
              }}
            >
              {!isHeadless && (
                <ProgressIndicator
                  message={
                    clientSecret
                      ? "Lets get you checked out and scheduled with your HomePro"
                      : "The more details you share, the better we can serve you"
                  }
                  progress={clientSecret ? 100 : 60}
                  step={clientSecret ? 3 : 2}
                />
              )}
            </Box>
          </Box>

          <Grid container spacing={5} justifyContent="center" pt="2rem">
            {modalLayout !== "spot-booking" && (
              <Grid item xs={12} md={4}>
                <AppointmentDetailsCard
                  booking_date={selectedPlan?.booking_date}
                  timezone={selectedPlan?.timezone}
                  timezone_name={selectedPlan?.timezone_name}
                  amount={selectedPlan?.amount}
                  appliedPromoCode={appliedPromoCode}
                  paymentDiscount={appliedPromoCode?.discount}
                  taxType={selectedPlan?.tax_type}
                  taxAmount={selectedPlan?.tax_amount}
                  totalAmount={selectedPlan?.total_amount}
                  summary={selectedPlan?.summary}
                  label={selectedPlan?.label}
                  icon={selectedPlan?.icon}
                />
              </Grid>
            )}

            <Grid item xs={12} md={modalLayout === "spot-booking" ? 12 : 8}>
              {clientSecret ? (
                <Elements options={options} stripe={stripePromise}>
                  {(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "").includes(
                    "_test_"
                  ) && (
                    <Box
                      position={"absolute"}
                      bgcolor={COLORS.STRIPE_PURPLE}
                      borderRadius={25}
                      px={4}
                      py={0.5}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      boxShadow={2}
                      right={60}
                      top={20}
                    >
                      <Typography color={COLORS.WHITE} fontWeight={"bold"}>
                        SANDBOX
                      </Typography>
                    </Box>
                  )}
                  <CheckoutForm
                    email={values.email}
                    clientSecret={clientSecret}
                    setShouldBlock={setShouldBlock}
                  />
                </Elements>
              ) : (
                <Paper
                  elevation={modalLayout === "spot-booking" ? 0 : 1}
                  sx={{ padding: "2rem" }}
                >
                  <Grid container spacing={1}>
                    <PersonalInfo
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      handleSubmit={handleSubmit}
                      isUpdatingBooking={isUpdatingBooking}
                      isValid={isValid}
                      optionalAddress={optionalAddress}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                    />
                  </Grid>
                </Paper>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </PageWrapper>
  );
};

export default Booking;
