
import React from 'react';

export default () => {
  return (
<svg width="13" height="22" viewBox="0 0 16 25" xmlns="http://www.w3.org/2000/svg">
    <g fill="#464E5F" fill-rule="nonzero">
        <path d="M14.525 25H1.712a1.11 1.11 0 0 1-1.11-1.11V1.11C.602.497 1.099 0 1.712 0h12.813c.613 0 1.11.497 1.11 1.11v22.78a1.11 1.11 0 0 1-1.11 1.11zM1.712.796a.313.313 0 0 0-.314.314v22.78c0 .173.14.314.314.314h12.813c.174 0 .314-.14.314-.314V1.11a.313.313 0 0 0-.314-.314H1.712z"/>
        <path d="M15.237 20.729H1a.398.398 0 1 1 0-.797h14.237a.398.398 0 1 1 0 .797zM10.468 2.505H5.769a.396.396 0 0 1-.356-.22L4.56.576A.397.397 0 0 1 4.915 0h6.407c.138 0 .266.071.338.19a.397.397 0 0 1 .018.386l-.854 1.709a.396.396 0 0 1-.356.22zm-4.453-.797h4.206l.456-.91L5.56.796l.455.912zM9.814 23.576h-3.39a.84.84 0 0 1-.84-.838v-.543a.84.84 0 0 1 .84-.839h3.39a.84.84 0 0 1 .838.839v.543a.84.84 0 0 1-.838.838zm-3.39-1.423a.042.042 0 0 0-.043.042v.543c0 .023.019.042.042.042h3.39a.042.042 0 0 0 .043-.042v-.543a.042.042 0 0 0-.042-.042h-3.39zM11.052 16.529h-.023a8.367 8.367 0 0 1-8.292-8.371A2.394 2.394 0 0 1 6.07 5.956a.41.41 0 0 1 .244.34l.018.108.061.377.183 1.142.264 1.66a.412.412 0 0 1-.136.375c-.21.185-.45.33-.712.43a5.6 5.6 0 0 0 2.886 2.887c.102-.26.247-.501.432-.711a.412.412 0 0 1 .376-.137l3.317.524c.142.022.262.12.312.257.02.056.122.341.154.531a2.34 2.34 0 0 1-.65 2.046 2.482 2.482 0 0 1-1.766.744zM5.129 6.563a1.598 1.598 0 0 0-1.595 1.595c0 4.138 3.364 7.535 7.503 7.574h.015c.45-.002.88-.184 1.195-.507a1.555 1.555 0 0 0 .435-1.348v-.006a1.55 1.55 0 0 0-.044-.172l-2.854-.45a1.594 1.594 0 0 0-.246.61.41.41 0 0 1-.542.319 6.383 6.383 0 0 1-3.908-3.91.413.413 0 0 1 .319-.54c.217-.04.425-.123.608-.247a2367.2 2367.2 0 0 0-.456-2.86 1.56 1.56 0 0 0-.162-.036l-.007-.001a1.583 1.583 0 0 0-.261-.021z"/>
    </g>
</svg>

  )
}