import axios from "axios";
import toggleToast from '../utils/messages'

const axiosOrder = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.response.use(
    (response) => {
      try {
        const {
          data: { status, displayMessage },
        } = response;

        if (status === "success" && displayMessage) {
          toggleToast(displayMessage, "success");
        }
      }
      catch(e){}

      return response;
    },
    (error) => {
      const {
        response: {
          data: { displayMessage },
        },
      } = error;
    
      toggleToast(!displayMessage ? 'Error occured.' : displayMessage, "error");
      return Promise.reject(error);
    }
  );

  return instance;
};

export { axiosOrder, toggleToast };
