import { FormControl, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { StyledSectionHeader, StyledTextarea } from "./styles";
import { HOME_INFORMATION } from "../../utils/data";
import { COLORS } from "../../utils/colors";
import { useStore } from "../../contexts/StoreContext";

const HomeInformation = ({ handleChange, values, errors }: any) => {
  const { selectedPlan } = useStore();
  const optionalAddress = selectedPlan?.optional_address;

  return (
    <Grid item xs={12} mb="32px">
      <Grid item xs={12} mb={4}>
        <StyledSectionHeader>HOME INFORMATION</StyledSectionHeader>
      </Grid>

      <Grid container spacing={4}>
        {!optionalAddress &&
          HOME_INFORMATION.map(({ label, name, size, maxLength, disabled }) => {
            return (
              <Grid item xs={12} md={size} key={name}>
                <TextField
                  fullWidth
                  label={label}
                  variant="outlined"
                  value={values[name]}
                  onChange={handleChange(name)}
                  error={!!errors[name]}
                  inputProps={{
                    maxLength: maxLength && maxLength,
                  }}
                  disabled={disabled}
                  helperText={
                    errors ? (
                      <span style={{ color: COLORS.RED }}>{errors[name]}</span>
                    ) : (
                      ""
                    )
                  }
                />
              </Grid>
            );
          })}

        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <StyledTextarea
              minRows={4}
              placeholder="Description"
              value={values.description}
              onChange={handleChange("description")}
              sx={{
                border: errors.description
                  ? `1px solid ${COLORS.RED}`
                  : "1px solid #a3a3a3",
                "::placeholder": {
                  fontSize: "0.9rem",
                  color: errors.description
                    ? `1px solid ${COLORS.RED}`
                    : "inherit",
                },
              }}
            />
          </FormControl>
          <Typography fontSize={".8rem"} marginTop={".25rem"}>
            {errors.description ? (
              <span style={{ color: COLORS.RED }}>{errors.description}</span>
            ) : (
              "Please provide a list of specific Home Maintenance issues you wish to discuss with your HomePro"
            )}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomeInformation;
