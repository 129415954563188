import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import AppointmentDetailsCard from "../../components/AppointmentDetailsCard";
import { useStore } from "../../contexts/StoreContext";
import { Download } from "@mui/icons-material";
import html2canvas from "html2canvas";
import Confirmation from "../../components/Confirmation";

import "../../App.css";
import BookSessionConfirmation from "../../components/BookSessionConfirmation";
import { NewBooking } from "../../global/interfaces";

const BookingConfirmation = () => {
  const {
    modalLayout,
    selectedPlan,
    isHeadless,
    createdBooking,
    setCreatedBooking,
    appliedPromoCode,
    bookingValues,
  } = useStore();
  if (process.env.REACT_APP_GOOGLE_ANALYTICS)
    ReactGA.send({
      hitType: "pageview",
      page: `/booking-confirmation/${selectedPlan?.onschedBookingId}`,
      title: "Booking Confirmed",
    });
  const handleDownloadReceipt = async () => {
    const downloadButton: any = document.getElementById("download-receipt");
    downloadButton.style.display = "none";
    const element: any = document.getElementById("receipt"),
      canvas = await html2canvas(element),
      data = canvas.toDataURL("image/jpg"),
      link = document.createElement("a");

    link.href = data;
    link.download = `Booking receipt - ${
      selectedPlan?.name | selectedPlan?.onschedBookingId
    }.jpg`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    downloadButton.style.display = "flex";
  };

  useEffect(() => {
    if (createdBooking?.data?.booking_secret !== "") {
      setCreatedBooking({
        ...createdBooking,
        data: { ...createdBooking?.data, booking_secret: "" },
      });
    }
  }, [createdBooking, createdBooking?.data?.booking_secret, setCreatedBooking]);

  if (modalLayout === "spot-booking") {
    const reciptDetails: NewBooking = {
      bookingId: bookingValues?.bookingId,
      bookingtime: bookingValues?.bookingtime,
      sessiontype: selectedPlan?.name,
      firstname: bookingValues?.firstname,
      lastname: bookingValues?.lastname,
      email: bookingValues?.email,
      mobile: bookingValues?.mobile,
      address: bookingValues?.address,
      city: bookingValues?.city,
      postalcode: bookingValues?.postalcode,
      province: bookingValues?.province,
      country: "Canada",
      yearbuilt: bookingValues?.yearbuilt,
      purchasedate: bookingValues?.purchasedate,
      description: bookingValues?.description,
    };

    return (
      <BookSessionConfirmation 
        isFuture={false} 
        details={reciptDetails} />
    );
  }

  const appointmentDetailsCard = (
    <AppointmentDetailsCard
      booking_date={selectedPlan?.booking_date}
      timezone={selectedPlan.timezone}
      timezone_name={selectedPlan.timezone_name}
      amount={selectedPlan?.amount}
      appliedPromoCode={appliedPromoCode}
      paymentDiscount={appliedPromoCode?.discount}
      taxAmount={selectedPlan?.tax_amount}
      taxType={selectedPlan?.tax_type}
      totalAmount={selectedPlan?.total_amount}
      onschedBookingId={selectedPlan?.onschedBookingId}
      summary={selectedPlan?.summary}
      label={selectedPlan?.label}
      icon={selectedPlan?.icon}
      buttonText="Download Receipt"
      buttonIcon={<Download />}
      handleClick={handleDownloadReceipt}
      primaryButton
    />
  );

  if (isHeadless)
    return (
      <div style={{ padding: "2rem" }}>
        {appointmentDetailsCard}
      </div>
    );

  return (
    <Confirmation
      smallText="The HomePorter Virtual Homecare Platform"
      mainText="Your session is scheduled"
      subText="Thank you for choosing HomePorter as your trusted home advisor."
      receiptCard={
        selectedPlan?.onschedBookingId && (
          appointmentDetailsCard
        )
      }
    />
  );
};

export default BookingConfirmation;
