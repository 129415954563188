import React from 'react';

export default () => {
  return (
<svg width="14" height="20" viewBox="0 0 19 25" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.028 0c4.978 0 9.028 4.062 9.028 9.054 0 6.196-8.08 15.291-8.423 15.675a.812.812 0 0 1-1.21 0C8.079 24.345 0 15.25 0 9.054 0 4.062 4.05 0 9.028 0zm0 4.499c-2.505 0-4.542 2.043-4.542 4.555 0 2.512 2.037 4.555 4.542 4.555 2.504 0 4.542-2.043 4.542-4.555 0-2.512-2.038-4.555-4.542-4.555z" fill="#464E5F" fill-rule="evenodd"/>
</svg>

  )
}
