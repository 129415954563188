import React, { useEffect } from "react";

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import Services from "../pages/Services";
import Schedule from "../pages/Schedule";
import Booking from "../pages/Booking";
import SpotBooking from "../pages/SpotBooking"
import BookingConfirmation from "../pages/BookingConfirmation";
import Reschedule from "../pages/Reschedule";
import Cancel from "../pages/Cancel";

const RedirectToExternalUrl: React.FC<{ url: string }> = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
};

const AppRoutes = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<MainLayout />}>
        <Route path="/" element={process.env.NODE_ENV === 'production' ? <RedirectToExternalUrl url="https://homeporter.com/pricing/." /> : <Services />} />
        <Route path="/services" element={<Services />} />
        <Route path="/schedule/:serviceId" element={<Schedule />} />
        <Route path="/booking/:serviceId" element={<Booking />} />
        <Route path="/spot-booking/:plan_id/:auth_token" element={<SpotBooking />} />
        <Route
          path="/booking-confirmation/:serviceId"
          element={<BookingConfirmation />}
        />
        <Route
          path="/reschedule/:bookingId/:confirmationCode"
          element={<Reschedule />}
        />
        <Route
          path="/cancel/:bookingId/:confirmationCode"
          element={<Cancel />}
        />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default AppRoutes;
