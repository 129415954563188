import {
  Grid,
  TextField,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import { COLORS } from "../../utils/colors";
import React, { useEffect, useState } from "react";
import DefaultButton from "../DefaultButton";
import { useStore } from "../../contexts/StoreContext";
import { East } from "@mui/icons-material";
import HomeOwnerContact from "./HomeOwnerContact";
import { StyledDivider, StyledSectionHeader } from "./styles";
import HomeAddress from "./HomeAddress";
import HomeInformation from "./HomeInformation";

const PersonalInfo = ({
  values,
  handleChange,
  errors,
  handleSubmit,
  isUpdatingBooking,
  setFieldValue,
  setFieldError,
}: any) => {
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const { modalLayout, appliedPromoCode, selectedPlan, isMobileVerified, setPromoCode } = useStore();
  const optionalAddress = !!selectedPlan?.optional_address;
  const canVerifyMobile = values.firstname && values.lastname && values.mobile && values.mobile;
  const canCompletePayment = optionalAddress
    ? canVerifyMobile
    : canVerifyMobile &&
      values.address &&
      values.city &&
      values.postalCode &&
      values.province &&
      values.country;

  const handlePromoCodeChange = (name: string) => (e?: any) => {
    let newValue: string = e.target.value
    setPromoCode(e.target.value)
    setFieldError(name, '')
    setFieldValue(name, newValue, false)
  }

  useEffect(() => {
    const errorMessage = !appliedPromoCode?.valid && appliedPromoCode?.displayMessage ? appliedPromoCode?.displayMessage : ''
    setFieldError("promoCode", errorMessage)
  }, [appliedPromoCode])

  return (
    <Box sx={{ display: "flex" }}>
      <Grid container spacing={3}>
        <HomeOwnerContact
          values={values}
          handleChange={handleChange}
          errors={errors}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
        />

        {(selectedPlan?.optional_verify_mobile || isMobileVerified) && (
        <Grid
          item
          md={12}
          // data-aos={!oneTimeSession && "fade-up"}
          // data-aos-duration="1000"
        >
          {!optionalAddress && (
            <>
              <StyledDivider />
              <HomeAddress
                handleChange={handleChange}
                values={values}
                errors={errors}
                optionalAddress={optionalAddress}
              />
            </>
          )}
          <StyledDivider />
          <HomeInformation
            handleChange={handleChange}
            values={values}
            errors={errors}
          />
          {selectedPlan.amount === 0 || !!appliedPromoCode?.valid ? null : (
            <>
              <StyledDivider />

              <Grid item xs={12} mb={"29px"}>
                <StyledSectionHeader>
                  DO YOU HAVE A PROMO CODE?
                </StyledSectionHeader>
              </Grid>

              <Grid item xs={12} mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      label={"Enter Promo Code"}
                      variant="outlined"
                      value={values["promoCode"]}
                      onChange={handlePromoCodeChange('promoCode')}
                      error={!!errors["promoCode"]}
                      inputProps={{
                        maxLength: 50,
                      }}
                      disabled={false}
                      helperText={
                        errors["promoCode"] ? (
                          <span style={{ color: COLORS.RED }}>
                            {errors["promoCode"]}
                          </span>
                        ) : (
                          ""
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems={"center"}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={isTermsChecked}
                    onChange={() => setIsTermsChecked(!isTermsChecked)}
                  />
                }
                label={
                  <Typography fontSize="12px">
                    I have read and agree to the{" "}
                    <Link
                      href="https://homeporter.com/terms-of-use/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      terms of service
                    </Link>{" "}
                    &{" "}
                    <Link
                      href="https://homeporter.com/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy policy
                    </Link>
                  </Typography>
                }
              />
              <DefaultButton
                variant="contained"
                handleClick={() => handleSubmit()}
                disabled={
                  !isTermsChecked || isUpdatingBooking || !canCompletePayment
                }
                isLoading={isUpdatingBooking}
                endIcon={<East />}
                style={modalLayout === 'spot-booking' ? { width: "13rem", position: 'absolute', top: '2rem', right: '2rem' } : { width: "13rem" }}
              >
                {selectedPlan.amount === 0 ? "Complete" : "Complete Payment"}
              </DefaultButton>
            </Box>
          </Grid>
        </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PersonalInfo;
