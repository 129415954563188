import { TextareaAutosize, Typography, styled, Divider } from "@mui/material";

export const StyledTextarea = styled(TextareaAutosize)({
  width: "100%",
  padding: "10px",
  borderRadius: "0.75rem",
  borderColor: "#a3a3a3",
  outline: "none",
  resize: "vertical",
  marginTop: "5px",
  fontFamily: "montserrat",
  minHeight: "40px",
  fontSize: "0.9rem",
});

export const StyledSectionHeader = styled(Typography)({
  paddingLeft: "0.5rem",
  fontSize: "0.9rem",
});

export const StyledDivider = styled(Divider)({
  marginBlock: "8px 24px",
});
