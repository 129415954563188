import { COLORS } from "../../../utils/colors";

export const useProgressIndicatorStyles = () => {
  return {
    iconWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "70px",
      width: "70px",
      borderRadius: "35px",
      border: `2px solid rgba(255, 110, 0, 0.1)`,
      position: "relative",
    },
    icon: {
      height: "35px",
      width: "35px",
      objectFit: "contain",
    },
    progress: {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      borderWidth: "4px",
      borderColor: COLORS.PRIMARY,
      boxSizing: "border-box",
      clipPath: "polygon(0 0, 100% 0, 100% 50%, 0 50%)",
    },
  };
};
