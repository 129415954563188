import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import AppRoutes from "./routes";
import theme from "./theme";
import { QueryClientProvider, QueryClient } from "react-query";
import { StoreProvider } from "./contexts/StoreContext";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";
import AOS from "aos";
import "./App.css";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();
if (process.env.REACT_APP_GOOGLE_ANALYTICS)
	ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS}`);
if (process.env.REACT_APP_HOTJAR_ID) {
	hotjar.initialize(
		Number(process.env.REACT_APP_HOTJAR_ID || 0),
		Number(process.env.REACT_APP_HOTJAR_VERSIONS || 6)
	);
}

function App() {
	useEffect(() => {
		AOS.init();
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<StoreProvider>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<AppRoutes />
					<ToastContainer />
				</ThemeProvider>
			</StoreProvider>
		</QueryClientProvider>
	);
}

export default App;
