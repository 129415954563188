import { useMutation, useQuery } from "react-query";
import { axiosOrder } from "../utils/axios-orders";

const useBookings = (bookingId?: string, confirmationCode?: string) => {
  const axios = axiosOrder();

  const createBooking = useMutation(async (payload: Record<string, any>) => {
    const response = await axios.post("bookings", payload);
    if (response.data.status === "success") {
      return response.data;
    } else {
      throw new Error("An error occurred while creating booking");
    }
  });

  const createSpotBooking = useMutation(async (payload: Record<string, any>) => {
    const response = await axios.post("bookings/spot-booking", payload);

    if (response.data?.status === "success") {
      return response.data;
    } else {
      throw new Error("An error occurred while creating spot booking");
    }
  });

  const updateBooking = useMutation(async (payload: Record<string, any>) => {
    const mutation = async () => {
      const { data } = await axios.put(
        `bookings/${payload.booking_id}`,
        payload.body
      );

      if (data.status === "success") {
        return data;
      } else {
        throw new Error("An error occurred while updating user");
      }
    };

    const response = await mutation();
    return response;
  });

  const getScheduledBooking = async () => {
    if (bookingId && confirmationCode) {
      const { data: response } = await axios.get(
        `bookings/${bookingId}/${confirmationCode}`
      );

      console.log(`getScheduledBooking: bookings/${bookingId}/${confirmationCode}`)

      if (response.status === "success") {

        console.log('getScheduledBooking response:', response.data)

        return response.data;
      }
      throw new Error("Failed to get booking");
    }
  };

  const { data: scheduledBooking, isFetching, refetch: refetchScheduledBooking } = useQuery(
    "scheduled-booking",
    getScheduledBooking,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  const cancelBooking = useMutation(async () => {
    const mutation = async () => {
      const { data } = await axios.put(
        `bookings/cancel/${bookingId}/${confirmationCode}`
      );

      if (data.status === "success") {
        return data;
      } else {
        throw new Error("An error occurred while canceling booking");
      }
    };

    const response = await mutation();
    return response;
  });

  const rescheduleBooking = useMutation(
    async (payload: Record<string, any>) => {
      const mutation = async () => {

        console.log('::::reschedulBooking', payload)

        const { data } = await axios.put(
          `bookings/reschedule/${bookingId}/${confirmationCode}`,
          payload
        );

        console.log('::::response: ', data)

        if (data.status === "success") {
          return data;
        } else {
          throw new Error("An error occurred while rescheduling booking");
        }
      };

      const response = await mutation();
      return response;
    }
  );

  const validateOTP = useMutation(async (payload: Record<string, any>) => {
    const validate = async () => {
      const res = await axios.post(`bookings/validate-otp`, {
        verification_code: payload.verificationCode,
        email: payload.email,
        booking_id: payload.bookingId,
      });

      if (res.data.status === "success") {
        return res.data;
      } else {
        throw new Error("An error occurred while validating OTP");
      }
    };

    const response = await validate();
    return response;
  });

  return {
    createBooking,
    createdBookingData: createBooking.data,
    createSpotBooking,
    isUpdatingBooking: updateBooking.isLoading,
    updateBooking,
    scheduledBooking,
    refetchScheduledBooking,
    cancelBooking,
    rescheduleBooking,
    isFetching,
    validateOTP,
    isValidatingOTP: validateOTP.isLoading,
  };
};

export default useBookings;
