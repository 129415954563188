import React from "react";
import { ArrowBackIosNew } from "@mui/icons-material";
import { Button, styled } from "@mui/material";
import { COLORS } from "../../utils/colors";

const StyledNavigator = styled(Button)({
  color: COLORS.DARK_GREY,
  border: "0",
  paddingInline: "12px",
});

const GoBack = ({ handleClick }: { handleClick: () => void }) => {
  return (
    <StyledNavigator onClick={handleClick} startIcon={<ArrowBackIosNew />}>
      Back
    </StyledNavigator>
  );
};

export default GoBack;
