import React from "react";
import { Grid, Box, styled, IconButton, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { Facebook, LinkedIn, YouTube } from "@mui/icons-material";

const StyledFooterWrapper = styled(Box)({
  width: "100%",
  position: "relative",
  backgroundColor: "transparent",
  padding: "1rem 2rem",
});

const Copyright = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="left">
      {" © "}
      {new Date().getFullYear()}
      {"  "}
      <Link color="inherit" href="https://homeporter.com/">
        HomePorter
      </Link>
      {". All rights reserved"}
    </Typography>
  );
};

const Footer = () => {
  return (
    <StyledFooterWrapper>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={6}>
          <Copyright />
        </Grid>

        <Grid
          item
          xs={6}
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item>
            <IconButton
              aria-label="Facebook Page"
              component={Link}
              href="https://www.facebook.com/homeporterca"
              target="_blank"
            >
              <Facebook />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="LinkedIn Page"
              component={Link}
              href="https://www.linkedin.com/company/home-porter/"
              target="_blank"
            >
              <LinkedIn />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="Youtube Page"
              component={Link}
              href="https://www.youtube.com/@askhomeporter"
              target="_blank"
            >
              <YouTube />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </StyledFooterWrapper>
  );
};

export default Footer;
