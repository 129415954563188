import { hotjar } from "react-hotjar";

export const trackHotJarUserFlow = (
  trackID: string | number,
  userProperty: string
) => {
  if (!process.env.REACT_APP_HOTJAR_ID) return
  
  if (hotjar.initialized()) {
    hotjar.identify(`${trackID}`, { userProperty });
  } else {
    hotjar.initialize(
      Number(process.env.REACT_APP_HOTJAR_ID || 0),
      Number(process.env.REACT_APP_HOTJAR_VERSIONS || 6)
    );
    hotjar.identify(`${trackID}`, { userProperty });
  }
};
